import { API } from "./api";

export const createGroup = (createGroupFormData) => {
  return API.post("/groups", createGroupFormData);
};

export const getGroup = (id) => {
  return API.get(`/groups/${id}`);
};

export const updateGroup = (groupId, updateGroupFormData) => {
  return API.put(`/groups/${groupId}`, updateGroupFormData);
};

export const getGroupsByUserId = (userId) => {
  return API.get(`/groups/user/${userId}`);
};

export const leaveGroupForUser = (groupId, userId) => {
  return API.delete(`/groups/${groupId}/user/${userId}`);
};

export const getGroupDetails = (groupId) => {
  return API.get(`/groups/${groupId}`);
};
