import React, { useEffect, useState } from "react";
import SignUpImage from "../../assets/images/Sign Up Image.png";
import PlayGroups from "../../assets/images/Play Groups.jpg";
import PlayGlobally from "../../assets/images/Play Globally.jpg";
import Header from "./components/Header";
import { Link, useNavigate } from "react-router-dom";

function PreHome() {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="bg-slate-100 min-h-screen">
        <Header />
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-12 md:py-16 lg:px-8 mx-6 lg:mx-12 rounded-lg mt-6">
          <img
            src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div
            className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-2xl font-bold tracking-tight text-white md:text-4xl">
              Welcome To Predictions App!
            </h2>
            <p className="mt-4 text-sm leading-normal text-gray-300">
              Welcome to the Cricket Predictions App – join the fervor of
              cricket and connect with enthusiasts worldwide. Make groups, play
              and track predictions across upcoming tournaments, and cheer on
              your favorite team with a global community that shares your
              passion for the game. Your love for cricket finds its perfect
              pitch here!
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6 mx-6 lg:mx-12 min-h-32 pt-6 lg:pb-4 pb-20">
          <div className="col-span-2 lg:col-span-1 shadow-md hover:shadow-lg">
            <div className="rounded-lg">
              <Link to="/groups">
                <img className="rounded-lg" src={PlayGroups} />
              </Link>
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1 shadow-md hover:shadow-lg">
            <div className="rounded-lg">
              <Link to="/global">
                <img className="rounded-lg" src={PlayGlobally} />
              </Link>
            </div>
          </div>
        </div>
        <p className="mx-6 lg:mx-12 text-green-600 text-center text-md pb-4">
          Don't worry you can switch later.
        </p>
      </div>
    </>
  );
}

export default PreHome;
