import React from "react";
import Layout from "../../components/Layout";
import { useState, useEffect } from "react";
import { getUsers } from "../../api/users.api";
import Table from "../../components/Table";
import { Link } from "react-router-dom";

const Users = () => {
  const columns = [
    {
      header: "Name",
      render: (user) => user.name,
    },
    {
      header: "Phone",
      render: (user) => user.phone,
    },
    {
      header: "Email",
      render: (user) => user.email,
    },
    // {
    //   header: "Actions",
    //   render: (user) => (
    //     <Link
    //       to={`/users/${user._id}`}
    //       className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
    //     >
    //       View
    //     </Link>
    //   ),
    // },
  ];
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Fetch items from the API using Axios
    getUsers()
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => console.error("Error fetching data: ", error));
    // axios
    //   .get("http://localhost:8080/api/users")
    //   .then((response) => {
    //     setUsers(response.data);
    //   })
    //   .catch((error) => console.error("Error fetching data: ", error));
  }, []);
  return (
  <Layout page="Users">
      <h1>User table</h1>

      <Table pagination={false} columns={columns} data={users}></Table>
    </Layout>
  );
};

export default Users;
