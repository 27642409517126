import { useState, useEffect } from "react";
import { BiHomeAlt } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { IoAddOutline } from "react-icons/io5";
import { createGroup, getGroupsByUserId } from "../../api/groups.api";
import "swiper/css";
import "swiper/css/pagination";
import Header from "./components/Header";
import GroupFormPopup from "./components/GroupFormPopup";
import { PencilIcon, UsersIcon } from "@heroicons/react/24/outline";
import HeaderImage from "../../assets/images/Header Image.png";

const Groups = () => {
  const navigate = useNavigate();
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [groupsLoading, setGroupsLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupDataToEdit, setGroupDataToEdit] = useState(null);
  const [createGroupPopupOpen, setCreateGroupPopupOpen] = useState(false);
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setLoggedInUser(storedUser);
    } else {
      navigate("/");
    }
    getGroups();
  }, []);

  const getGroups = () => {
    setGroupsLoading(true);
    const userID = JSON.parse(localStorage.getItem("user"))._id;
    getGroupsByUserId(userID)
      .then((res) => {
        setGroups(res.data);
        setGroupsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* <Modal /> */}
      <GroupFormPopup
        open={createGroupPopupOpen}
        setOpen={setCreateGroupPopupOpen}
        groupDataToEdit={groupDataToEdit}
        setGroupDataToEdit={setGroupDataToEdit}
        getGroups={getGroups}
        loggedInUser={loggedInUser}
      />

      <div className="bg-gray-100 min-h-screen">
        <Header />
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-12 md:py-16 lg:px-8 mx-6 lg:mx-12 rounded-lg mt-6">
          <img
            src={HeaderImage}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div
            className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-2xl font-bold tracking-tight text-white md:text-4xl">
              Welcome to Groups
            </h2>
            <p className="mt-4 text-sm leading-normal text-gray-300">
              You can Create new group or join existing groups to participate in
              and make your predictions.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-3 mx-6 lg:mx-12 pt-6 gap-4 mb-4 lg:pb-4 pb-20">
          {/* Show Group Here */}
          <div className="col-span-3 lg:col-span-1">
            <div className="bg-white rounded-lg px-4 py-4">
              <div className="border-2 border-dashed border-indigo-300 rounded-lg shadow-sm p-4 flex flex-col items-center justify-center bg-white">
                <div className="flex items-center justify-center mb-4">
                  <IoAddOutline className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="text-sm font-semibold text-gray-700 mb-4">
                  Create a new Group
                </p>
                <button
                  type="button"
                  onClick={() => setCreateGroupPopupOpen(true)}
                  className="text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                  Create New Group
                </button>
              </div>
            </div>
          </div>
          {groupsLoading ? (
            <div
              role="status"
              className="flex items-center justify-center h-full max-w-sm bg-gray-100 rounded-lg animate-pulse dark:bg-gray-300"
            >
              <UsersIcon className="h-10 w-10 text-gray-400" />
              <span className="sr-only">Loading...</span>
            </div>
          ) : groups.length === 0 ? (
            <div className="col-span-3 lg:col-span-2">
              <div className="bg-white p-4 rounded-lg">
                <h3 className="font-medium text-lg mb-4">No Groups Joined</h3>
                <p className="text-sm text-gray-400">
                  You have not joined any groups yet. Click on the button above
                  to create a new group or join an existing one.
                </p>
              </div>
            </div>
          ) : (
            <h1 className="lg:hidden font-semibold text-gray-400">
              Joined Groups
            </h1>
          )}
          {groups?.map((group, index) => (
            <div className="col-span-3 lg:col-span-1 h-full" key={index}>
              <button
                onClick={() => navigate(`/groups/${group._id}`)}
                className="w-full"
              >
                {/* Card container with shadow and rounded corners */}
                <div
                  className="rounded-lg overflow-hidden shadow bg-white h-full border-t-8 border-b-8"
                  style={{
                    borderColor: group.color,
                  }}
                >
                  {/* Card content */}
                  <div className="px-4 py-2 flex flex-col justify-between">
                    {/* Title and status */}
                    <div className="flex justify-between items-center mb-6">
                      <h1 className="text-xl font-bold text-gray-900">
                        {group.name}
                      </h1>
                      <div className="flex space-x-2">
                        <span className="inline-flex items-center px-3 py-1 rounded-sm text-sm font-medium bg-blue-200 text-blue-800">
                          {group.members.find(
                            (member) => member.user._id === loggedInUser._id
                          )?.role === "admin"
                            ? "Admin"
                            : "Member"}
                        </span>
                        {
                          // If user is admin, show edit button
                          group.members.find(
                            (member) => member.user._id === loggedInUser._id
                          )?.role === "admin" && (
                            <span
                              className="inline-flex items-center px-3 py-2 rounded-sm text-sm font-medium bg-green-200 text-gray-800 hover:bg-green-300"
                              onClick={(e) => {
                                e.stopPropagation();
                                setGroupDataToEdit(group);
                                setCreateGroupPopupOpen(true);
                              }}
                            >
                              <PencilIcon className="h-5 w-5 text-green-700" />
                            </span>
                          )
                        }
                      </div>
                    </div>

                    {/* Stats section */}
                    <div className="text-center">
                      <div className="flex justify-around mb-4">
                        {/* Participants */}
                        <div className="flex flex-col items-center bg-slate-100 px-6 rounded py-2">
                          <span className="text-4xl font-bold text-gray-700">
                            {group.members.length}
                          </span>
                          <span className="text-sm font-medium text-gray-500">
                            Participants
                          </span>
                        </div>

                        {/* Tournaments */}
                        <div className="flex flex-col items-center bg-slate-100 px-6 rounded py-2">
                          <span className="text-4xl font-bold text-gray-700">
                            {group.tournamentsParticipated}
                          </span>
                          <span className="text-sm font-medium text-gray-500">
                            Tournaments
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Groups;
