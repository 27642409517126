import React, { useState } from "react";
import { BiGroup, BiHomeAlt, BiUser } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Logo from "../../../assets/images/Logo.png";
import { PiGlobeStand } from "react-icons/pi";
import SaveLoginInfoPopup from "./SaveLoginInfoPopup";

const navigation = [
  { name: "Home", icon: BiHomeAlt, href: "/home" },
  { name: "Groups", href: "/groups" },
  { name: "Profile", href: "/profile" },
];

const Header = () => {
  const navigate = useNavigate();
  const [saveLoginInfoPopupOpen, setSaveLoginInfoPopupOpen] = useState(false);

  return (
    <>
      <SaveLoginInfoPopup
        open={saveLoginInfoPopupOpen}
        setOpen={setSaveLoginInfoPopupOpen}
      />
      <header className="bg-white">
        <nav
          className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8"
          aria-label="Global"
        >
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-12 w-auto" src={Logo} alt="" />
            </a>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            <NavLink
              to="/main"
              className={({ isActive }) =>
                `text-sm font-semibold leading-6 flex items-center gap-x-1 ${
                  isActive
                    ? "border-b-2 border-indigo-700 text-gray-900"
                    : "text-gray-900"
                }`
              }
            >
              <BiHomeAlt />
              Main
            </NavLink>
            <NavLink
              to="/global"
              className={({ isActive }) =>
                `text-sm font-semibold leading-6 flex items-center gap-x-1 ${
                  isActive
                    ? "border-b-2 border-indigo-700 text-gray-900"
                    : "text-gray-900"
                }`
              }
            >
              <PiGlobeStand />
              Global
            </NavLink>
            <NavLink
              to="/groups"
              className={({ isActive }) =>
                `text-sm font-semibold leading-6 flex items-center gap-x-1 ${
                  isActive
                    ? "border-b-2 border-indigo-700 text-gray-900"
                    : "text-gray-900"
                }`
              }
            >
              <BiGroup />
              Groups
            </NavLink>

            <NavLink
              to="/profile"
              className={({ isActive }) =>
                `text-sm font-semibold leading-6 flex items-center gap-x-1 ${
                  isActive
                    ? "border-b-2 border-indigo-700 text-gray-900"
                    : "text-gray-900"
                }`
              }
            >
              <BiUser />
              Profile
            </NavLink>
          </div>
          <div className="flex flex-1 items-center justify-end gap-x-4">
            <h2 className="text-sm font-medium text-gray-400 truncate">
              {JSON.parse(localStorage.getItem("user")).name}
            </h2>
            <button
              type="button"
              onClick={() => {
                const savedUsers =
                  JSON.parse(localStorage.getItem("savedUsers")) || [];
                const loggedInUser = JSON.parse(localStorage.getItem("user"));
                const userToken = localStorage.getItem("token");
                if (
                  savedUsers.find((user) => user.user._id === loggedInUser._id)
                ) {
                  setSaveLoginInfoPopupOpen(false);
                  localStorage.removeItem("user");
                  localStorage.removeItem("token");
                  navigate("/");
                  return;
                }
                // localStorage.removeItem("token");
                // localStorage.removeItem("user");
                // navigate("/");
                setSaveLoginInfoPopupOpen(true);
              }}
              className="rounded-md bg-indigo-200 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Log Out
            </button>
          </div>
        </nav>
        <div className="fixed bottom-0 left-1/2 bg-indigo-800 w-full rounded-t-2xl transform -translate-x-1/2 z-10">
          <div className="flex justify-around lg:hidden lg:gap-x-12 px-4 py-2">
            <NavLink
              to="/main"
              className={({ isActive }) =>
                `text-sm font-semibold leading-6 py-2 flex items-center gap-x-1 ${
                  isActive
                    ? "border-b-2 border-white text-white"
                    : "text-indigo-200"
                }`
              }
            >
              <BiHomeAlt />
              Main
            </NavLink>
            <NavLink
              to="/global"
              className={({ isActive }) =>
                `text-sm font-semibold leading-6 py-2 flex items-center gap-x-1 ${
                  isActive
                    ? "border-b-2 border-white text-white"
                    : "text-indigo-200"
                }`
              }
            >
              <PiGlobeStand />
              Global
            </NavLink>
            <NavLink
              to="/groups"
              className={({ isActive }) =>
                `text-sm font-semibold leading-6 py-2 flex items-center gap-x-1 ${
                  isActive
                    ? "border-b-2 border-white text-white"
                    : "text-indigo-200"
                }`
              }
            >
              <BiGroup />
              Groups
            </NavLink>

            <NavLink
              to="/profile"
              className={({ isActive }) =>
                `text-sm font-semibold leading-6 py-2 flex items-center gap-x-1 ${
                  isActive
                    ? "border-b-2 border-white text-white"
                    : "text-indigo-200"
                }`
              }
            >
              <BiUser />
              Profile
            </NavLink>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
