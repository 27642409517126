import React from "react";
import { getLocalDateTime, getTimeFromDate } from "../../../utils/time";
import { IoLocationOutline } from "react-icons/io5";
import { baseURL } from "../../../api/api";

const Schedule = ({ schedule, tournament }) => {
  return (
    <div className="bg-white rounded-lg px-4 py-4 mb-24 sm:mb-4">
      <h1 className="">{tournament?.name} Schedule</h1>
      <ul
        role="list"
        className="divide-y divide-gray-200 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl my-5"
      >
        {schedule.map((item, index) => (
          <li
            key={index}
            className="relative grid grid-cols-12 gap-x-6 px-4 py-3 md:py-5 hover:bg-gray-50 sm:px-6"
          >
            <div className="hidden md:flex min-w-0 gap-x-4 col-span-0 md:col-span-3 items-center">
              <div className="min-w-0 flex-auto">
                <p className="text-xs sm:text-sm font-semibold leading-6 text-gray-900">
                  <span className="absolute inset-x-0 -top-px bottom-0" />
                  {getTimeFromDate(item?.dateTime)?.substring(
                    0,
                    getTimeFromDate(item?.dateTime).indexOf(" ")
                  )}
                </p>
                <p className="mt-1 flex text-xs leading-5 text-gray-500">
                  {getTimeFromDate(item?.dateTime)?.substring(
                    getTimeFromDate(item?.dateTime).indexOf(" ") + 1
                  )}
                </p>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="flex md:hidden items-center justify-between mb-2">
                <p className="text-xs sm:text-sm font-semibold text-gray-900">
                  <span className="absolute inset-x-0 -top-px bottom-0" />
                  {getLocalDateTime(item?.dateTime)?.split(",")[0]}
                </p>
                <p className="mt-1 flex text-xs text-gray-500">
                  {getLocalDateTime(item?.dateTime)?.split(",")[1]}
                </p>
              </div>
              <div className="items-center grid grid-cols-5 ">
                <div className="flex flex-col items-center col-span-2">
                  <img
                    src={`${baseURL}/public/${item?.team1?.image}`}
                    alt={item?.team1?.name}
                    className="w-12 h-auto"
                  />
                  <p className="text-xs sm:text-sm text-nowrap font-semibold leading-6 text-gray-900">
                    {item?.team1?.name}
                  </p>
                </div>
                <div className="col-span-1 flex-1 justify-center items-center">
                  <p className="text-xs md:text-sm font-semibold leading-6 text-gray-900 bg-gray-200 rounded-full w-10 h-10 mx-2 flex justify-center items-center">
                    VS
                  </p>
                </div>
                <div className="flex flex-col items-center col-span-2">
                  <img
                    src={`${baseURL}/public/${item?.team2?.image}`}
                    alt={item?.team2?.name}
                    className="w-12 h-auto"
                  />
                  <p className="text-xs sm:text-sm text-nowrap font-semibold leading-6 text-gray-900">
                    {item?.team2?.name}
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden col-span-3 md:flex justify-end items-center">
              <IoLocationOutline className="h-5 w-5 text-gray-400" />
              <p className="text-xs font-semibold leading-tight text-gray-500">
                {item?.venue}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Schedule;
