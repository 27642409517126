import { API } from "./api";

export const getTeams = () => {
  return API.get("/teams");
};

export const createTeam = (formData) => {
  return API.post("/teams", formData);
};

export const updateTeam = (teamId, formData) => {
  return API.put(`/teams/${teamId}`, formData);
};
