import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SignUpImage from "../../assets/images/Sign Up Image.png";
import SignUpMobile from "../../assets/images/Sign Up Image Mobile.png.jpg";
import Logo from "../../assets/images/Logo.png";
import { signUp } from "../../api/users.api";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

//signup form
const Signup = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
  });
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (localStorage?.getItem("token") && localStorage?.getItem("user")) {
      navigate("/main");
    }
  }, [navigate]);

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };
  const handleSignup = async (event) => {
    event.preventDefault();
    signUp(userData).then((res) => {
      navigate("/main");
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.result));
    });
  };
  return (
    <div
      style={{
        backgroundImage: `url('${SignUpMobile}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className="flex min-h-screen flex-1 lg:bg-white"
    >
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-24 w-auto" src={Logo} alt="Your Company" />
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Welcome To Predictions App!
            </h2>
          </div>

          <div className="mt-10">
            <div>
              <form method="POST" className="space-y-6" onSubmit={handleSignup}>
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      id="name"
                      name="name"
                      type="name"
                      autoComplete="name"
                      required
                      placeholder="Enter your name"
                      className="block w-full ps-3 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="phoneNumber"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Phone Number
                  </label>
                  <div className="mt-2">
                    <input
                      id="phoneNumber"
                      name="phone"
                      type="text"
                      required
                      placeholder="Enter your phone number"
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      placeholder="Enter your email"
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                  <div className="mt-2">
                    <div className="flex justify-between items-center">
                      <input
                        id="password"
                        name="password"
                        type={visible ? "text" : "password"}
                        autoComplete="current-password"
                        required
                        placeholder="Enter your password"
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        onChange={handleChange}
                      />
                      <div className="ms-2 text-gray-500 bg-white py-2.5 px-2 rounded-md border cursor-pointer">
                        {visible ? (
                          <IoEyeOffOutline
                            onClick={() => {
                              setVisible(!visible);
                            }}
                          />
                        ) : (
                          <IoEyeOutline
                            onClick={() => {
                              setVisible(!visible);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Register
                  </button>
                </div>
              </form>
              {/* Already have an account? */}
              <div className="mt-6">
                <p className="text-sm leading-6 text-gray-500">
                  Already have an account?{" "}
                  <Link
                    to={"/"}
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={SignUpImage}
          alt=""
        />
      </div>
    </div>
  );
};

export default Signup;
