import React from "react";
import Trophy from "../../../assets/images/trophy.png";

const Leaderboard = ({ leaderboard }) => {
  return (
    <>
      <div className="bg-white rounded-lg px-4 py-4 mb-24 sm:mb-4">
        <div className="flex items-center justify-between mb-4">
          <h2 className="">Leaderboard</h2>
          <button>{/* Icon or button for additional actions */}</button>
        </div>
        <ul>
          {/* Example List Item */}
          <li className="mb-4 rounded-lg">
            {leaderboard?.map((item, index) => (
              <div className="bg-gray-100" key={index}>
                <div className="w-full mx-auto">
                  {/* Super Mario Bros */}
                  <div
                    className={`h-24 flex items-center justify-between p-4 mb-2 rounded-lg ${
                      index === 0 ? "bg-indigo-600" : "bg-gray-100"
                    } ${index === 0 ? "text-white" : "text-gray-700"} ${
                      index === 0 ? "shadow" : "shadow-sm"
                    }`}
                  >
                    <div className="flex space-x-4">
                      {index === 0 && (
                        <img
                          src={Trophy}
                          alt="Descriptive Alt Text"
                          className="w-12 h-auto"
                        />
                      )}
                      <div className="flex items-center">
                        <span className="text-2xl font-bold mr-2">
                          {item?.position}
                        </span>
                        <h2 className="text-xl font-bold">
                          {item?.user?.name}
                        </h2>
                      </div>
                    </div>
                    <div>
                      <span className="text-3xl font-bold">{item?.points}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </li>
          {/* Repeat for other items, changing the badge position and color as needed */}
        </ul>
      </div>
    </>
  );
};

export default Leaderboard;
