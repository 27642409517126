import Swal from "sweetalert2";

export const confirm = (message, callback) => {
  Swal.fire({
    title: "Are you sure?",
    text: message,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
};

export const success = (message) => {
  Swal.fire({
    icon: "success",
    title: "Success",
    text: message,
  });
};

export const error = (message) => {
  Swal.fire({
    icon: "error",
    title: "Error",
    text: message,
  });
};

export const warning = (message) => {
  Swal.fire({
    icon: "warning",
    title: "Warning",
    text: message,
  });
};
