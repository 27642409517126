import { formatInTimeZone } from "date-fns-tz";

export const getLocalDateTime = (dateString) => {
  // Parse the date string and create a Date object
  const date = new Date(dateString);

  // Define options for formatting
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  // Use Intl.DateTimeFormat to format the date in local timezone
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const checkIfTimeIsPassed = (dateString) => {
  // Parse the date string and create a Date object
  const date = new Date(dateString);

  // Define options for formatting
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  // Use Intl.DateTimeFormat to format the date in local timezone
  const formattedDate = new Intl.DateTimeFormat("en-US", options)?.format(date);
  const currentDate = new Date();
  const isTimePassed = date < currentDate;

  return { formattedDate, isTimePassed };
};

export const getTimeFromDate = (dateString) => {
  const date = new Date(dateString);
  return formatInTimeZone(date, getUserTimezone(), "dd/MM/yyyy hh:mm a");
};

const getUserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
