import { API } from "./api";

export const createParticipant = ({ tournament }) => {
  return API.post("/participants", { tournament });
};
export const createParticipantsForGroup = ({
  tournament,
  group,
  rules,
  favoriteTeam,
}) => {
  return API.post(`/participants/group`, {
    tournament,
    group,
    rules,
    favoriteTeam,
  });
};

export const updateParticipant = (participantId, data) => {
  return API.put(`/participants/${participantId}`, data);
};

export const getParticipatedTournamentTodayMatches = (tournamentId) => {
  return API.get(`/participants/tournament/${tournamentId}/todaymatches`);
};

export const getParticipatedTournamentPreviousMatches = (tournamentId) => {
  return API.get(`/participants/tournament/${tournamentId}/previousmatches`);
};

export const getParticipatedTournamentTodayMatchesFromGroup = (
  tournamentId,
  groupId
) => {
  return API.get(
    `/participants/tournament/${tournamentId}/group/${groupId}/todaymatches`
  );
};
export const getParticipatedTournamentPreviousMatchesFromGroup = (
  tournamentId,
  groupId
) => {
  return API.get(
    `/participants/tournament/${tournamentId}/group/${groupId}/previousmatches`
  );
};

export const getLeaderboardForTournament = (tournamentId) => {
  return API.get(`/participants/tournament/${tournamentId}/leaderboard`);
};

export const getLeaderboardForTournamentInGroup = (tournamentId, groupId) => {
  return API.get(
    `/participants/tournament/${tournamentId}/group/${groupId}/leaderboard`
  );
};

export const defineRulesForTournament = ({ tournament, rules }) => {
  return API.post("/participants/rules", { tournament, rules });
};
