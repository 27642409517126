import axios from "axios";

// export const baseURL = "http://localhost:8080";

export const baseURL = "https://api.predicteam.net";

export const API = axios.create({
  baseURL: baseURL + "/api",
});

API.interceptors.request.use((req) => {
  const token = localStorage.getItem("token");
  if (token) {
    req.headers.authorization = token;
  }
  return req;
});
