import "./App.css";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ForgotPassword from "./pages/auth/ForgotPassword";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navigation from "./navigation/Navigation";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/*" element={<Navigation />} />
      </Routes>
    </Router>
  );
}

export default App;
