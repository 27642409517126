import {
  ChevronDownIcon,
  ChevronUpDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import ReactPaginate from "react-paginate";

const Table = ({
  pagination = true,
  columns,
  pageCount,
  setItemOffset,
  data,
  dataCount,
  itemsPerPage,
  initialSort,
  sort,
  setSort,
}) => {
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % dataCount;
    setItemOffset(newOffset);
  };

  return (
    <div className="my-2 overflow-x-auto">
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                {columns.map((column, i) => (
                  <th
                    key={i}
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6"
                    onClick={() => {
                      if (column?.sortable) {
                        setSort({
                          ...initialSort,
                          [column?.fieldName]:
                            sort[column?.fieldName] === 0
                              ? 1
                              : sort[column?.fieldName] === 1
                              ? -1
                              : 0,
                        });
                      }
                    }}
                  >
                    <a href="#" className="group inline-flex">
                      {column?.header}
                      {column?.sortable &&
                        (sort[column?.fieldName] === 0 ? (
                          <span className="invisible ml-2 flex-none rounded text-gray-300 group-hover:visible">
                            <ChevronUpDownIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : sort[column?.fieldName] === 1 ? (
                          <span className="ml-2 flex-none rounded text-gray-500 bg-gray-200">
                            <ChevronDownIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        ) : (
                          sort[column?.fieldName] === -1 && (
                            <span className="ml-2 flex-none rounded text-gray-500 bg-gray-200">
                              <ChevronUpIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          )
                        ))}
                    </a>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white border-b-lg">
              {data?.map((element, i) => (
                <tr key={i} className={i % 2 === 0 ? undefined : "bg-gray-50"}>
                  {columns.map((column, i) => (
                    <td
                      key={i}
                      className="whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-6"
                    >
                      {column.render(element)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {pagination && (
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            className="isolate inline-flex -space-x-px rounded-lg shadow-sm bg-white mt-6"
            pageLinkClassName="h-full relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex"
            previousClassName="px-5 relative inline-flex items-center rounded-l-lg px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            activeLinkClassName="z-10 bg-green-600 text-white"
            nextClassName="px-5 relative inline-flex items-center rounded-r-lg px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            breakClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
          />
        )}
      </div>
    </div>
  );
};

export default Table;
