import react from "react";
import Layout from "../../components/Layout.js";
import { useState, useEffect } from "react";
import {
  getTournaments,
  createTournament,
  updateTournament,
} from "../../api/tournaments.api.js";
import Table from "../../components/Table.js";
import { Link } from "react-router-dom";
import Datepicker from "react-tailwindcss-datepicker";
import Creatable, { useCreatable } from "react-select/creatable";
import { getTeams } from "../../api/teams.api.js";
import { error, success } from "../../utils/notifications.js";

const Tournaments = () => {
  const columns = [
    {
      header: "Name",
      render: (tournament) => tournament.name,
    },
    {
      header: "Start Date",
      render: (tournament) => tournament.startDate?.split("T")[0],
    },
    {
      header: "End Date",
      render: (tournament) => tournament.endDate?.split("T")[0],
    },
    {
      header: "Teams",
      render: (tournament) =>
        tournament.teams?.map((team) => team.name).join(", "),
    },
    {
      header: "Actions",
      render: (tournament) => (
        <div className="flex gap-x-2">
          <button
            onClick={() => {
              setFormData({
                ...tournament,
                teams: tournament.teams?.map((team) => ({
                  value: team._id,
                  label: team.name,
                })),
              });
              setSelectedTeams(
                tournament?.teams?.map((team) => ({
                  value: team?._id,
                  label: team?.name,
                }))
              );
              setValue({
                startDate: tournament.startDate,
                endDate: tournament.endDate,
              });
            }}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Edit
          </button>
        </div>
      ),
    },
  ];
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [value, setValue] = useState({
    startDate: null,
    endDate: null,
  });
  const [teams, setTeams] = useState([]);

  const [formData, setFormData] = useState({
    type: "",
    name: "",
    startDate: "",
    endDate: "",
    teams: [],
  });
  useEffect(() => {
    getTeams()
      .then((response) => {
        setTeams(response.data);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (formData?._id) {
        await updateTournament(formData?._id, {
          ...formData,
          startDate: value.startDate,
          endDate: value.endDate,
          teams: formData?.teams?.map((team) => team.label),
        }).then((res) => {
          success("Tournament updated successfully");
          setFormData({
            type: "",
            name: "",
            startDate: "",
            endDate: "",
            teams: [],
          });
          setSelectedTeams([]);
          setValue({
            startDate: null,
            endDate: null,
          });
          setTournaments(
            tournaments.map((tournament) =>
              tournament._id === formData._id ? res.data : tournament
            )
          );
        });
      } else {
        await createTournament({
          ...formData,
          startDate: value.startDate,
          endDate: value.endDate,
          teams: formData?.teams?.map((team) => team.label),
        }).then((res) => {
          success("Tournament created successfully");
          setFormData({
            type: "",
            name: "",
            startDate: "",
            endDate: "",
            teams: [],
          });
          setSelectedTeams([]);
          setValue({
            startDate: null,
            endDate: null,
          });
          setTournaments([...tournaments, res.data]);
        });
      }
    } catch (err) {
      error("Error creating tournament");
      console.error(err);
    }
  };
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const [tournaments, setTournaments] = useState([]);
  useEffect(() => {
    getTournaments()
      .then((response) => {
        setTournaments(response.data);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);
  return (
    <Layout page="Tournaments">
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Create Tournament
            </h2>
          </div>

          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Tournament Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Enter the tournament information
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Tournament Type
                </label>
                <div className="mt-2">
                  <select
                    onChange={handleChange}
                    value={formData.type}
                    id="type"
                    name="type"
                    autoComplete="tournament-type"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value=""> Select Type</option>
                    <option value="International">International</option>
                    <option value="League">League</option>
                  </select>
                </div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Tournament Name
                </label>
                <div className="mt-2">
                  <input
                    onChange={handleChange}
                    value={formData.name}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Tournament Name"
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <label
                  htmlFor="start_date"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Date
                </label>
                <div className="mt-2">
                  <Datepicker
                    value={value}
                    onChange={setValue}
                    type="date"
                    name="tournament_date"
                    id="tournament_date"
                    autoComplete=""
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-1">
                <label
                  htmlFor="teams"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Teams
                </label>
                <div className="mt-2">
                  <Creatable
                    isMulti
                    options={teams.map((team) => ({
                      value: team._id,
                      label: team.name,
                    }))}
                    value={selectedTeams}
                    onChange={(value) => {
                      setSelectedTeams(value);
                      setFormData({ ...formData, teams: value });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </form>
      <h1>Tournament table</h1>
      <Table pagination={false} columns={columns} data={tournaments}></Table>
    </Layout>
  );
};

export default Tournaments;
