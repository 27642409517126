import React, { useState, useEffect } from "react";
import { LuImagePlus } from "react-icons/lu";
import Layout from "../../components/Layout.js";
import { getTeams, createTeam, updateTeam } from "../../api/teams.api.js"; // Adjust API calls as per your setup
import Table from "../../components/Table.js";
import { Link } from "react-router-dom";
import { baseURL } from "../../api/api.js";

const Teams = () => {
  const columns = [
    {
      header: "Image",
      render: (team) => (
        <img
          src={`${baseURL}/public/${team.image}`}
          alt={team.name}
          className="h-10 w-10 rounded-full"
        />
      ),
    },
    {
      header: "Name",
      render: (team) => team.name,
    },
    {
      header: "Actions",
      render: (team) => (
        <button
          onClick={() => {
            setFormData(team);
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Edit
        </button>
      ),
    },
  ];

  const [teams, setTeams] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    image: "", // Assuming you are storing image URL or path
  });
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    getTeams()
      .then((response) => {
        setTeams(response.data);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const data = new FormData();
      data.append("name", formData.name);
      data.append("image", selectedImage);
      if (formData?._id) {
        await updateTeam(formData?._id, data)
          .then((response) => {
            setTeams(
              teams.map((team) =>
                team._id === formData._id ? response.data : team
              )
            );
            setFormData({ name: "", image: "" });
            setSelectedImage(null);
          })
          .catch((error) => console.error("Error updating team: ", error));
      } else {
        await createTeam(data)
          .then((response) => {
            setTeams([...teams, response.data]);
            setFormData({ name: "", image: "" });
            setSelectedImage(null);
          })
          .catch((error) => console.error("Error creating team: ", error));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  return (
    <Layout page="Teams">
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Create Team
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Enter the team information below.
            </p>
            <div className="mt-10 grid grid-cols-2 gap-x-6 gap-y-8">
              <div>
                <div className="text-left lg:col-span-7 md:col-span-7 sm:col-span-12">
                  <div className="w-1/3 mt-2 ">
                    <label
                      htmlFor="image"
                      className="mx-auto bg-gray-100 rounded-lg h-40 w-40 inset-0 flex items-center justify-center cursor-pointer"
                    >
                      {selectedImage || (formData?._id && formData.image) ? (
                        <img
                          src={
                            selectedImage
                              ? URL.createObjectURL(selectedImage)
                              : `${baseURL}/public/${formData.image}`
                          }
                          alt="Selected"
                          className="rounded-lg h-full w-full object-cover"
                        />
                      ) : (
                        <LuImagePlus className="text-gray-400 h-14 w-14 p-2" />
                      )}
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      id="image"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-full">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Team Name
                  </label>
                  <input
                    onChange={handleChange}
                    value={formData.name}
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Team Name"
                    autoComplete="team-name"
                    className="mt-2 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500"
          >
            Save
          </button>
        </div>
      </form>
      <h1>Team Table</h1>
      <Table pagination={false} columns={columns} data={teams}></Table>
    </Layout>
  );
};

export default Teams;
