import { API } from "./api";

export const signIn = (formData) => {
  return API.post("/users/signin", formData);
};

export const signUp = (formData) => {
  return API.post("/users/signup", formData);
};

export const getUsers = () => {
  return API.get("/users");
};

export const getProfileInformation = () => {
  return API.get("/users/profile");
};
