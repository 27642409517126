import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { getUsers } from "../../../api/users.api";
import { createGroup, updateGroup } from "../../../api/groups.api";

const GroupFormPopup = ({
  open,
  setOpen,
  groupDataToEdit,
  setGroupDataToEdit,
  getGroups,
  loggedInUser,
}) => {
  const cancelButtonRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [createGroupFormData, setCreateGroupFormData] = useState({
    name: "",
    members: [],
    color: "#000000",
  });
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (groupDataToEdit) {
      setCreateGroupFormData({
        name: groupDataToEdit.name,
        members: groupDataToEdit.members?.map((member) => ({
          user: member.user._id,
          role: member.role,
        })),
        color: groupDataToEdit.color,
      });
    }
  }, [groupDataToEdit]);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      if (users.length === 0) {
        getUsers().then((res) => {
          setUsers(
            res.data?.filter(
              (user) => user._id !== loggedInUser?._id && user.role === "user"
            )
          );
          setFilteredUsers(
            res.data?.filter(
              (user) =>
                user._id !== loggedInUser?._id &&
                user.role === "user" &&
                user.name.toLowerCase().includes(searchTerm.toLowerCase())
            )
          );
        });
      } else {
        setFilteredUsers(
          users.filter(
            (user) =>
              user._id !== loggedInUser?._id &&
              user.role === "user" &&
              user.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }
    } else {
      setFilteredUsers([]);
    }
  }, [searchTerm, loggedInUser]);

  const handleCreateGroupChange = (e) => {
    setCreateGroupFormData({
      ...createGroupFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateGroup = async (e) => {
    e.preventDefault(e);
    try {
      if (groupDataToEdit) {
        updateGroup(groupDataToEdit._id, createGroupFormData)
          .then((res) => {
            getGroups();
            setGroupDataToEdit(null);
            setCreateGroupFormData({
              name: "",
              members: [],
              color: "#000000",
            });
            setOpen(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        createGroup({
          ...createGroupFormData,
          members: [
            ...createGroupFormData.members,
            {
              user: loggedInUser._id,
              role: "admin",
            },
          ],
        })
          .then((res) => {
            getGroups();
            // reset form data
            setCreateGroupFormData({
              name: "",
              members: [],
              color: "#000000",
            });
            setOpen(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMemberCheckboxChange = (e, user) => {
    if (e.target.checked) {
      // Add member
      setCreateGroupFormData({
        ...createGroupFormData,
        members: [
          ...createGroupFormData.members,
          {
            user: user._id,
            role: "user",
          },
        ],
      });
    } else {
      // Remove member
      setCreateGroupFormData({
        ...createGroupFormData,
        members: createGroupFormData.members.filter(
          (member) => member.user !== user._id
        ),
      });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  {/* <div className="flex items-center justify-center w-full bg-green-200 h-6 rounded-full text-center mb-4">
                      <h1 className="text-green-600 text-sm">Happy Family</h1>
                    </div> */}
                  <div className="text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Create New Group
                    </Dialog.Title>
                  </div>
                  <form method="post" onSubmit={handleCreateGroup}>
                    <div>
                      <label
                        htmlFor="group-name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Group Name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="group-name"
                          name="name"
                          placeholder="Enter Group Name"
                          onChange={handleCreateGroupChange}
                          value={createGroupFormData.name}
                          required
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                    {/* group color */}
                    <div className="mt-4">
                      <label
                        htmlFor="group-color"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Group Color
                      </label>
                      <div className="mt-2">
                        <input
                          type="color"
                          id="group-color"
                          name="color"
                          onChange={handleCreateGroupChange}
                          value={createGroupFormData.color}
                          required
                          className="w-full h-10 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        />
                      </div>
                    </div>
                    <div className="mt-4">
                      <label
                        htmlFor="group-color"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Add Members
                      </label>
                      {users?.length > 0 &&
                        users
                          ?.filter((user) =>
                            createGroupFormData.members.some(
                              (member) => member.user === user._id
                            )
                          )
                          .map((user, index) => (
                            <div
                              key={index}
                              className="flex items-center gap-x-2"
                            >
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  handleMemberCheckboxChange(e, user)
                                }
                                value={createGroupFormData.members}
                                className="rounded text-blue-600 focus:ring-blue-500 border-gray-300"
                                checked={createGroupFormData.members.some(
                                  (member) => member.user === user._id
                                )}
                              />
                              <label className="ml-2 text-gray-700">
                                {`${user.name} (${user.email})`}
                              </label>
                            </div>
                          ))}
                      <div className="mt-2">
                        <div className="flex items-center border-b border-indigo-500 py-1 mb-2">
                          <input
                            className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight"
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                          />
                          {/* <button
                              className="flex-shrink-0 border-transparent border-4 text-indigo-600 font-semibold text-sm py-1 px-2 rounded"
                              type="button"
                            >
                              Search
                            </button> */}
                        </div>

                        {searchTerm?.length < 3
                          ? "Enter at least 3 characters to see results"
                          : filteredUsers.map((user, index) => (
                              <div
                                key={index}
                                className="flex items-center gap-x-2"
                              >
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleMemberCheckboxChange(e, user)
                                  }
                                  value={createGroupFormData.members}
                                  className="rounded text-blue-600 focus:ring-blue-500 border-gray-300"
                                  checked={createGroupFormData.members.some(
                                    (member) => member.user === user._id
                                  )}
                                />
                                <label className="ml-2 text-gray-700">
                                  {`${user.name} (${user.email})`}
                                </label>
                              </div>
                            ))}
                        {/* <input
                            type="checkbox"
                            className="rounded text-blue-600 focus:ring-blue-500 border-gray-300"
                          />
                          <label className="ml-2 text-gray-700">Check me</label> */}
                      </div>
                    </div>

                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                        onClick={() => setOpen(false)}
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GroupFormPopup;
