import { API } from "./api";

export const createMatch = (formData) => {
  return API.post("/matches", formData);
};

export const getMatches = () => {
  return API.get("/matches");
};

export const updateMatchResult = (id, formData) => {
  return API.put(`/matches/${id}/result`, formData);
};

export const getTodayMatches = () => {
  return API.get("/matches/today");
};

export const deleteMatch = (id) => {
  return API.delete(`/matches/${id}`);
};
