import Layout from "../../components/Layout.js";
import { useState, useEffect } from "react";
import { IoFilter } from "react-icons/io5";
import { createMatch, deleteMatch, getMatches } from "../../api/matches.api.js";
import Table from "../../components/Table.js";
import { Link } from "react-router-dom";
import { getTeams } from "../../api/teams.api.js";
import {
  getTournamentTeams,
  getTournaments,
} from "../../api/tournaments.api.js";
import { success, error, confirm } from "../../utils/notifications.js";
import {
  getFormattedDate,
  getLocalDateTime,
  getTimeFromDate,
} from "../../utils/time.js";
import UpdateWinningTeamPopup from "./components/UpdateWinningTeamPopup.js.js";

const Matches = () => {
  const [teams, setTeams] = useState([]);
  useEffect(() => {
    getTeams().then((res) => {
      setTeams(res.data);
    });
  }, []);
  const [tournaments, setTournaments] = useState([]);
  useEffect(() => {
    getTournaments().then((res) => {
      setTournaments(res.data);
    });
  }, []);
  const [formData, setFormData] = useState({
    tournament: "",
    matchType: "",
    team1: "",
    team2: "",
    dateTime: "",
    venue: "",
  });
  const [matchDetails, setMatchDetails] = useState(null);
  const [matchDetailsPopupOpen, setMatchDetailsPopupOpen] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.team1 === formData.team2) {
      error("Team 1 and Team 2 cannot be same");
      return;
    }
    try {
      await createMatch({
        ...formData,
        dateTime: new Date(formData.dateTime),
        team1: formData.team1,
        team2: formData.team2,
      })
        .then((res) => {
          success("Match Created Successfully");
          setMatches([...matches, res.data]);
        })
        .catch((err) => {
          error(err?.response?.data?.message || "Error Creating Match");
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteMatch = async (id) => {
    confirm("Are you sure you want to delete this match?", async () => {
      await deleteMatch(id)
        .then((res) => {
          success("Match Deleted Successfully");
          setMatches(matches.filter((match) => match._id !== id));
        })
        .catch((error) => {
          error("Error Deleting Match");
          console.log(error);
        });
    });
  };

  const columns = [
    {
      header: "Tournament Name",
      render: (match) => match?.tournament?.name,
    },
    {
      header: "Match",
      render: (match) => match?.team1?.name + " vs " + match?.team2?.name,
    },
    {
      header: "Date & Time",
      // convert to local date time and display in format "DD/MM/YYYY HH:MM AM/PM"
      render: (match) => <div>{getTimeFromDate(match?.dateTime)}</div>,
    },
    {
      header: "Venue",
      render: (match) => match?.venue,
    },
    {
      header: "Winner",
      render: (match) => match?.winningTeam?.name,
    },
    {
      header: "Action",
      render: (match) => (
        <div className="flex gap-4">
          <button
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => {
              setMatchDetails(match);
              setMatchDetailsPopupOpen(true);
            }}
          >
            Update Winning Team
          </button>
          <button
            onClick={() => handleDeleteMatch(match._id)}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  const [matches, setMatches] = useState([]);
  useEffect(() => {
    getAllMatches();
  }, []);
  useEffect(() => {
    if (formData?.tournament !== "")
      getTournamentTeams(formData?.tournament).then((res) => {
        setTeams(res.data);
        setFormData({ ...formData, team1: "", team2: "" });
      });
  }, [formData?.tournament]);

  const getAllMatches = () => {
    getMatches().then((res) => {
      setMatches(res.data);
    });
  };

  return (
    <Layout page="Matches">
      <UpdateWinningTeamPopup
        open={matchDetailsPopupOpen}
        setOpen={setMatchDetailsPopupOpen}
        matchDetails={matchDetails}
        getAllMatches={getAllMatches}
      />
      <form onSubmit={handleSubmit}>
        <div className="space-y-6">
          <div className="border-b border-gray-900/10 pb-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Create Match
            </h2>
          </div>

          <div className="border-b border-gray-900/10 pb-6">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Match Information
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              Enter the Match information
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Tournament Name
                </label>
                <div className="mt-2">
                  <select
                    onChange={handleChange}
                    value={formData.tournament}
                    id="tournament"
                    name="tournament"
                    autoComplete="tournament"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value=""> Select Tournament</option>
                    {tournaments.map((tournament, index) => (
                      <option value={tournament._id} key={index}>
                        {tournament.name}
                      </option>
                    ))}
                  </select>
                </div>
                <label
                  htmlFor="matchType"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Match Type
                </label>
                <div className="mt-2">
                  <select
                    onChange={handleChange}
                    value={formData.matchType}
                    id="matchType"
                    name="matchType"
                    autoComplete="match-type"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value=""> Select Type</option>
                    <option value="group">Group Match</option>
                    <option value="knockout">Knockout Match</option>
                    <option value="final">Final Match</option>
                  </select>
                </div>

                <label
                  htmlFor="team-1"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Team 1
                </label>
                <div className="mt-2">
                  <select
                    onChange={handleChange}
                    value={formData.team1}
                    name="team1"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value=""> Select Team</option>
                    {teams.map((team, index) => (
                      <option value={team._id} key={index}>
                        {team.name}
                      </option>
                    ))}
                  </select>
                </div>

                <h1 className="text-center text-xl">VS</h1>
                <label
                  htmlFor="team-2"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Team 2
                </label>
                <div className="mt-2">
                  <select
                    onChange={handleChange}
                    value={formData.team2}
                    name="team2"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value=""> Select Team</option>
                    {teams.map((team, index) => (
                      <option value={team._id} key={index}>
                        {team.name}{" "}
                      </option>
                    ))}
                  </select>
                </div>
                <label
                  htmlFor="date"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Date & Time
                </label>
                <div className="mt-2">
                  <input
                    type="dateTime-local"
                    name="dateTime"
                    id="dateTime"
                    autoComplete="off"
                    required
                    onChange={handleChange}
                    value={formData.dateTime}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
                <label
                  htmlFor="venue"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Venue
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="venue"
                    id="venue"
                    autoComplete="venue"
                    placeholder="Venue"
                    required
                    onChange={handleChange}
                    value={formData?.venue}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="sm:col-span-1"></div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </form>
      <div className="shadow-lg rounded-lg border mt-6">
        <div className="flex justify-between items-center mt-6 px-4">
          <h1>Matches Table</h1>
          <button className="flex items-center bg-slate-200 px-5 py-2 rounded-sm hover:bg-indigo-600 hover:text-white font-medium">
            <IoFilter className="inline-block" />
            Filter
          </button>
        </div>
        <Table pagination={false} columns={columns} data={matches}></Table>
      </div>
    </Layout>
  );
};

export default Matches;
