import React from "react";
import { HiUserCircle } from "react-icons/hi2";

const GroupInfo = ({
  groupDataLoading,
  groupData,
  handleLeaveGroup,
  checkAdmin,
}) => {
  return (
    <div className="bg-white rounded-lg px-4 py-4 mb-24 sm:mb-4">
      <div className="flex flex-col sm:flex-row space-x-0 sm:space-x-3 sm:justify-between sm:items-center p-4 border-b">
        <div className="">
          <div
            style={{
              backgroundColor: `${groupData?.groupColor}`,
              width: "100%",
              height: "8px",
              borderRadius: "4px",
              marginBottom: "8px",
            }}
          ></div>
          <h1 className="flex flex-col text-lg font-medium">
            {groupData?.groupName}
          </h1>
          <div className="flex gap-6 mt-2">
            <div className="flex justify-around gap-4">
              <div className="bg-gray-100 text-gray-500 px-4 py-1 rounded-md">
                {groupData?.members?.length} Members
              </div>
              <div className="bg-gray-100 text-gray-500 px-4 py-1 rounded-md">
                {groupData?.tournamentCount === 1
                  ? `${groupData?.tournamentCount} Tournament`
                  : `${groupData?.tournamentCount} Tournaments`}
              </div>
            </div>
          </div>
        </div>
        {!groupDataLoading && !checkAdmin() && (
          <button
            className="bg-indigo-200 text-indigo-600 px-4 py-1 rounded-md hover:text-white hover:bg-indigo-600 mt-4 sm:mt-0"
            onClick={handleLeaveGroup}
          >
            Leave Group
          </button>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-2 lg:col-span-1">
          <h1 className="ps-4 mt font-bold">Members: </h1>
          <div className="w-full flex items-center p-4 border-b">
            <HiUserCircle className="w-10 h-10 text-gray-200" />
            <div className="w-full flex justify-between items-center">
              <h2 className="font-semibold ps-2">
                {
                  groupData?.members?.find((member) => member?.role === "admin")
                    ?.user?.name
                }
              </h2>
              <p className="bg-green-200 text-green-700 px-2.5 py-1 rounded-md text-sm">
                Admin
              </p>
            </div>
          </div>
          {groupData?.members
            ?.filter((member) => member?.role === "user")
            ?.map((member, index) => (
              <div
                className="w-full flex items-center p-4 border-b"
                key={index}
              >
                <HiUserCircle className="w-10 h-10 text-gray-200" />
                <div className="w-full flex justify-between items-center">
                  <h2 className="font-sm ps-2">{member?.user?.name}</h2>
                  <p className="bg-blue-100 text-blue-700 px-2.5 py-1 rounded-md text-sm">
                    Member
                  </p>
                </div>
              </div>
            ))}
        </div>

        <div className="col-span-2 lg:col-span-1"></div>
      </div>
    </div>
  );
};

export default GroupInfo;
