import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCurrentTournamentsForGroup,
  getTournamentSchedule,
  getUpcomingTournamentsForGroup,
  getPreviousTournamentsForGroup,
} from "../../api/tournaments.api";
import {
  createParticipantsForGroup,
  getParticipatedTournamentTodayMatchesFromGroup,
  updateParticipant,
  getLeaderboardForTournamentInGroup,
  getParticipatedTournamentPreviousMatchesFromGroup,
} from "../../api/participants.api";
import {
  getGroup,
  getGroupDetails,
  leaveGroupForUser,
} from "../../api/groups.api";
import { getGroupsByUserId } from "../../api/groups.api";
import "swiper/css";
import "swiper/css/pagination";
import HeaderImage from "../../assets/images/Header Image.png";
import Header from "./components/Header";
import { error, success, confirm } from "../../utils/notifications";
import SelectTournament from "../../assets/images/Selesct Group.png";
import GroupFormPopup from "./components/GroupFormPopup";
import TodayMatches from "./components/TodayMatches";
import GetPredictionPopup from "./components/GetPredictionPopup";
import DefineRulesPopup from "./components/DefineRulesPopup";
import AcceptInvitePopup from "./components/AcceptInvitePopup";
import Leaderboard from "./components/Leaderboard";
import { MdOutlineLeaderboard } from "react-icons/md";
import PreviousMatches from "./components/PreviousMatches";
import Schedule from "./components/Schedule";
import { RxCalendar } from "react-icons/rx";
import { TbCalendarCancel } from "react-icons/tb";
import { MdOutlineSportsCricket } from "react-icons/md";
import { MdOutlineGroup } from "react-icons/md";
import { LuTrophy } from "react-icons/lu";
import { CgCalendarNext } from "react-icons/cg";
import RulesPopup from "./components/RulesPopup";
import GroupInfo from "./components/GroupInfo";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const GroupDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [activeTournamentTab, setActiveTournamentTab] = useState(0);
  const [activeMainTab, setActiveMainTab] = useState(0);
  const tournamentTabs = [
    {
      id: 0,
      name: "Joined",
      icon: LuTrophy,
      current: activeTournamentTab === 0,
    },
    {
      id: 1,
      name: "Upcoming",
      icon: CgCalendarNext,
      current: activeTournamentTab === 1,
    },
    {
      id: 2,
      name: "Previous",
      icon: CgCalendarNext,
      current: activeTournamentTab === 2,
    },
  ];
  const mainTabs = [
    {
      id: 0,
      name: "Group Details",
      icon: MdOutlineGroup,
      current: activeMainTab === 0,
    },
    {
      id: 1,
      name: "Today Matches",
      icon: MdOutlineSportsCricket,
      current: activeMainTab === 1,
    },
    {
      id: 2,
      name: "Previous Matches",
      icon: TbCalendarCancel,
      current: activeMainTab === 2,
    },
    {
      id: 3,
      name: "Leaderboard",
      icon: MdOutlineLeaderboard,
      current: activeMainTab === 3,
    },
    {
      id: 4,
      name: "Schedule",
      icon: RxCalendar,
      current: activeMainTab === 4,
    },
  ];
  const [currentTournaments, setCurrentTournaments] = useState([]);
  const [upcomingTournaments, setUpcomingTournaments] = useState([]);
  const [previousTournaments, setPreviousTournaments] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [rulesPopupOpen, setRulesPopupOpen] = useState(false);
  const [defineRulesPopupOpen, setDefineRulesPopupOpen] = useState(false);
  const [acceptInvitePopupOpen, setAcceptInvitePopupOpen] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [groups, setGroups] = useState([]);
  const [groupDataLoading, setGroupDataLoading] = useState(false);
  const [upcomingTournamentsLoading, setUpcomingTournamentsLoading] =
    useState(false);
  const [joinTournamentLoading, setJoinTournamentLoading] = useState(false);
  const [joinedTournamentsLoading, setJoinedTournamentsLoading] =
    useState(false);
  const [groupData, setGroupData] = useState({});
  const [matchDetails, setMatchDetails] = useState(null);
  const [matchDetailsPopupOpen, setMatchDetailsPopupOpen] = useState(false);
  const [createGroupPopupOpen, setCreateGroupPopupOpen] = useState(false);
  const [
    participatedTournamentTodayMatchesFromGroup,
    setParticipatedTournamentTodayMatchesFromGroup,
  ] = useState([]);
  const [
    participatedTournamentPreviousMatchesFromGroup,
    setParticipatedTournamentPreviousMatchesFromGroup,
  ] = useState([]);
  const [prediction, setPrediction] = useState({});
  const [groupDetails, setGroupDetails] = useState({});
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setLoggedInUser(storedUser);
    } else {
      navigate("/");
    }
    setUpcomingTournamentsLoading(true);
    setJoinedTournamentsLoading(true);
    const userID = JSON.parse(localStorage.getItem("user"))._id;
    getGroupsByUserId(userID).then((res) => {
      setGroups(res.data);
    });
    getCurrentTournamentsForGroup(id)
      .then((res) => {
        setCurrentTournaments(res.data);
        setJoinedTournamentsLoading(false);
        if (res.data.length > 0) {
          setSelectedTournament(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    getUpcomingTournamentsForGroup(id)
      .then((res) => {
        setUpcomingTournaments(res.data);
        setUpcomingTournamentsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    getPreviousTournamentsForGroup(id)
      .then((res) => {
        setPreviousTournaments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    getGroupDetails(id).then((res) => {
      setGroupDetails(res.data);
    });
    getGroupData();
  }, [id]);
  useEffect(() => {
    if (selectedTournament !== null) {
      getMatchesForPrediction();
      getPreviousMatches();
      getLeaderboardForGroup();
      getScheduleForTournament();
    }
  }, [selectedTournament]);
  useEffect(() => {
    // Set the activeMainTab to the index of the "Today Matches" tab (index 1)
    setActiveMainTab(1);
  }, [selectedTournament]);
  const checkAdmin = () => {
    if (groupDetails.members) {
      const isAdmin = groupDetails.members.find(
        (member) =>
          member.role === "admin" &&
          member?.user?._id?.toString() === loggedInUser?._id
      );
      if (isAdmin) {
        return true;
      }
    }
    return false;
  };
  const handleJoinTournamentForGroup = (tournamentId, rules, favoriteTeam) => {
    setJoinTournamentLoading(true);
    createParticipantsForGroup({
      tournament: tournamentId,
      group: id,
      rules,
      favoriteTeam,
    })
      // defineRulesForTournament({ tournamentId, rules })
      .then((res) => {
        success("Tournament Joined Successfully");
        getGroupData();
        setActiveTournamentTab(0);
        setDefineRulesPopupOpen(false);
        getCurrentTournamentsForGroup(id)
          .then((res) => {
            setCurrentTournaments(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        getUpcomingTournamentsForGroup(id)
          .then((res) => {
            setUpcomingTournaments(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        setJoinTournamentLoading(false);
      })
      .catch((err) => {
        error("Failed to join tournament");
        console.log(err);
        setJoinTournamentLoading(false);
      });
  };

  const handleAcceptTournamentInvite = (e, participantId, favoriteTeam) => {
    e.preventDefault();
    updateParticipant(participantId, { favoriteTeam, active: true })
      .then((res) => {
        success("Tournament Joined Successfully");
        setAcceptInvitePopupOpen(false);
        getCurrentTournamentsForGroup(id)
          .then((res) => {
            setCurrentTournaments(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
        getUpcomingTournamentsForGroup(id)
          .then((res) => {
            setUpcomingTournaments(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        error("Failed to join tournament");
        console.log(err);
      });
  };

  const getMatchesForPrediction = () => {
    getParticipatedTournamentTodayMatchesFromGroup(selectedTournament?._id, id)
      .then((res) => {
        setParticipatedTournamentTodayMatchesFromGroup(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPreviousMatches = () => {
    getParticipatedTournamentPreviousMatchesFromGroup(
      selectedTournament?._id,
      id
    )
      .then((res) => {
        setParticipatedTournamentPreviousMatchesFromGroup(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGroupData = () => {
    setGroupDataLoading(true);
    getGroup(id)
      .then((res) => {
        setGroupDataLoading(false);
        setGroupData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLeaderboardForGroup = () => {
    getLeaderboardForTournamentInGroup(selectedTournament?._id, id)
      .then((res) => {
        setLeaderboard(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getScheduleForTournament = () => {
    getTournamentSchedule(selectedTournament?._id)
      .then((res) => {
        setSchedule(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLeaveGroup = () => {
    // confirm("Are you sure you want to leave the group?", leaveGroup);
    // leaveGroupForUser(id, loggedInUser._id)
    //   .then((res) => {
    //     success("Group Left Successfully");
    //     navigate("/groups");
    //   })
    //   .catch((err) => {
    //     error("Failed to leave group");
    //     console.log(err);
    //   });

    confirm("Are you sure you want to leave the group?", () => {
      leaveGroupForUser(id, loggedInUser._id)
        .then((res) => {
          success("Group Left Successfully");
          navigate("/groups");
        })
        .catch((err) => {
          error("Failed to leave group");
          console.log(err);
        });
    });
  };

  return (
    <>
      {/* <Modal /> */}
      <GroupFormPopup
        open={createGroupPopupOpen}
        setOpen={setCreateGroupPopupOpen}
        groups={groups}
        setGroups={setGroups}
        loggedInUser={loggedInUser}
      />
      {matchDetails && (
        <GetPredictionPopup
          open={matchDetailsPopupOpen}
          setOpen={setMatchDetailsPopupOpen}
          group={id}
          matchDetails={matchDetails}
          getMatchesForPrediction={getMatchesForPrediction}
        />
      )}
      <DefineRulesPopup
        open={defineRulesPopupOpen}
        setOpen={setDefineRulesPopupOpen}
        tournament={selectedTournament}
        handleJoinTournament={handleJoinTournamentForGroup}
      />
      <AcceptInvitePopup
        open={acceptInvitePopupOpen}
        setOpen={setAcceptInvitePopupOpen}
        tournament={selectedTournament}
        handleAcceptInvite={handleAcceptTournamentInvite}
      />
      <RulesPopup
        open={rulesPopupOpen}
        setOpen={setRulesPopupOpen}
        tournament={selectedTournament}
      />
      <div className="bg-gray-100">
        <Header />
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-12 md:py-16 lg:px-8 mx-6 lg:mx-12 rounded-lg mt-6">
          <img
            src={HeaderImage}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div
            className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-2xl font-bold tracking-tight text-white md:text-4xl">
              Your Group
            </h2>
            <p className="mt-4 text-sm leading-normal text-gray-300">
              You can make Prediction for the matches and earn points
            </p>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-3 mx-6 lg:mx-12 min-h-32 pt-6">
          <div className="col-span-3 lg:col-span-2">
            <div className="mb-3">
              <div className="block">
                <div className="border-b border-gray-200">
                  <nav
                    className="-mb-px flex space-x-2 sm:space-x-4 overflow-x-auto"
                    aria-label="Tabs"
                  >
                    {mainTabs.map((tab) => (
                      <button
                        key={tab.name}
                        className={classNames(
                          tab.current
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium text-nowrap text-xs sm:text-sm"
                        )}
                        onClick={() => setActiveMainTab(tab.id)}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        <tab.icon
                          className={classNames(
                            tab.current
                              ? "text-indigo-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "-ml-0.5 mr-2 h-5 w-5"
                          )}
                          aria-hidden="true"
                        />
                        <span>{tab.name}</span>
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
            <div>
              <div>
                {selectedTournament === null ? (
                  <>
                    {activeMainTab === 0 ? (
                      <GroupInfo
                        groupData={groupData}
                        handleLeaveGroup={handleLeaveGroup}
                        checkAdmin={checkAdmin}
                      />
                    ) : (
                      <div className="bg-transparent rounded-lg px-4 py-4 mb-4">
                        <div className="flex flex-col items-center">
                          <img className="w-64 h-auto" src={SelectTournament} />
                          <h1 className="text-lg text-gray-400 font-mediun mb-12">
                            Please select a tournament
                          </h1>
                        </div>
                      </div>
                    )}
                  </>
                ) : activeMainTab === 0 ? (
                  <GroupInfo
                    groupDataLoading={groupDataLoading}
                    groupData={groupData}
                    handleLeaveGroup={handleLeaveGroup}
                    checkAdmin={checkAdmin}
                  />
                ) : activeMainTab === 1 ? (
                  <TodayMatches
                    participatedTournamentTodayMatches={
                      participatedTournamentTodayMatchesFromGroup
                    }
                    tournament={selectedTournament}
                    setRulesPopupOpen={setRulesPopupOpen}
                    setMatchDetails={setMatchDetails}
                    setMatchDetailsPopupOpen={setMatchDetailsPopupOpen}
                  />
                ) : activeMainTab === 2 ? (
                  <PreviousMatches
                    participatedTournamentPreviousMatches={
                      participatedTournamentPreviousMatchesFromGroup
                    }
                    tournament={selectedTournament}
                    setRulesPopupOpen={setRulesPopupOpen}
                    setMatchDetails={setMatchDetails}
                    setMatchDetailsPopupOpen={setMatchDetailsPopupOpen}
                  />
                ) : activeMainTab === 3 ? (
                  <Leaderboard leaderboard={leaderboard} />
                ) : (
                  activeMainTab === 4 && (
                    <Schedule
                      schedule={schedule}
                      tournament={selectedTournament}
                    />
                  )
                )}
              </div>
            </div>
          </div>

          <div className="lg:order-last order-first col-span-3 lg:col-span-1 lg:block">
            <div className="bg-white rounded-lg px-4 py-4 h-auto lg:min-h-[360px] mb-4 overflow-auto">
              <h1 className="font-bold">Tournaments</h1>
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="tabs"
                    name="tabs"
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    defaultValue={
                      tournamentTabs.find((tab) => tab.current).name
                    }
                    onChange={(event) =>
                      setActiveTournamentTab(Number(event.target.value))
                    }
                  >
                    {tournamentTabs.map((tab) => (
                      <option key={tab.name} value={tab.id}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="border-b border-gray-200">
                    <nav
                      className="-mb-px flex space-x-8 overflow-x-auto"
                      aria-label="Tabs"
                    >
                      {tournamentTabs.map((tab) => (
                        <button
                          key={tab.name}
                          className={classNames(
                            tab.current
                              ? "border-indigo-500 text-indigo-600"
                              : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                            "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium"
                          )}
                          onClick={() => setActiveTournamentTab(tab.id)}
                          aria-current={tab.current ? "page" : undefined}
                        >
                          <tab.icon
                            className={classNames(
                              tab.current
                                ? "text-indigo-500"
                                : "text-gray-400 group-hover:text-gray-500",
                              "-ml-0.5 mr-2 h-5 w-5"
                            )}
                            aria-hidden="true"
                          />
                          <span>{tab.name}</span>
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
              {activeTournamentTab === 0 && (
                <div className="mt-4">
                  <h1 className="font-bold">Joined Tournaments</h1>
                  {joinTournamentLoading ? (
                    <div
                      role="status"
                      className="max-w-md p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse my-5"
                    >
                      <div className="flex items-center justify-between">
                        <div>
                          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-300 w-24 mb-2.5"></div>
                          <div className="w-32 h-2 bg-gray-100 rounded-full dark:bg-gray-100"></div>
                        </div>
                        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-12"></div>
                      </div>
                      <div className="flex items-center justify-between pt-4">
                        <div>
                          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-300 w-24 mb-2.5"></div>
                          <div className="w-32 h-2 bg-gray-100 rounded-full dark:bg-gray-100"></div>
                        </div>
                        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-12"></div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    currentTournaments.length === 0 && (
                      <div className="flex justify-center items-center h-16 rounded-lg bg-slate-100 my-5">
                        <h1 className="ml-4">No Tournaments Joined</h1>
                      </div>
                    )
                  )}
                  {currentTournaments.map((tournament, index) => (
                    <div
                      key={index}
                      className={`flex justify-between items-center h-16 rounded-lg my-5 cursor-pointer ${
                        selectedTournament?._id === tournament?._id
                          ? "bg-indigo-200"
                          : "bg-slate-100"
                      }`}
                      onClick={() => setSelectedTournament(tournament)}
                    >
                      <h1 className="ml-4">{tournament?.name}</h1>
                      <button
                        type="button"
                        className={`mr-4 inline-flex items-center gap-x-2 rounded-md px-1.5 py-1  text-sm font-semibold shadow-sm ${
                          selectedTournament?._id === tournament?._id
                            ? "bg-indigo-600 text-white"
                            : "bg-slate-200 text-slate-400"
                        }`}
                      >
                        Points: {tournament?.points}
                      </button>
                    </div>
                  ))}
                </div>
              )}
              {activeTournamentTab === 1 && (
                <div className="mt-4">
                  <h1 className="font-bold">Upcoming Tournaments</h1>
                  {upcomingTournamentsLoading ? (
                    <div
                      role="status"
                      className="max-w-md p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse my-5"
                    >
                      <div className="flex items-center justify-between">
                        <div>
                          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-300 w-24 mb-2.5"></div>
                          <div className="w-32 h-2 bg-gray-100 rounded-full dark:bg-gray-100"></div>
                        </div>
                        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-12"></div>
                      </div>
                      <div className="flex items-center justify-between pt-4">
                        <div>
                          <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-300 w-24 mb-2.5"></div>
                          <div className="w-32 h-2 bg-gray-100 rounded-full dark:bg-gray-100"></div>
                        </div>
                        <div className="h-2.5 bg-gray-100 rounded-full dark:bg-gray-100 w-12"></div>
                      </div>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    upcomingTournaments.length === 0 && (
                      <div className="flex justify-center items-center h-16 rounded-lg bg-slate-100 my-5">
                        <h1 className="ml-4">No Upcoming Tournaments</h1>
                      </div>
                    )
                  )}
                  {upcomingTournaments.map((tournament, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center h-16 rounded-lg bg-slate-100 my-5"
                    >
                      <h1 className="ml-4">{tournament?.name}</h1>
                      {checkAdmin() && (
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedTournament(tournament);
                            setDefineRulesPopupOpen(true);
                          }}
                          className="mr-4 inline-flex items-center gap-x-2 rounded-md bg-slate-200 px-1.5 py-1  text-sm font-semibold text-slate-400 shadow-sm"
                          disabled={joinTournamentLoading}
                        >
                          {joinTournamentLoading ? "Joining..." : "Join"}
                        </button>
                      )}
                      {tournament?.active === false && (
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedTournament(tournament);
                            setAcceptInvitePopupOpen(true);
                          }}
                          className="mr-4 inline-flex items-center gap-x-2 rounded-md bg-slate-200 px-1.5 py-1  text-sm font-semibold text-slate-400 shadow-sm"
                        >
                          Accept Invite
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {activeTournamentTab === 2 && (
                <div className="mt-4">
                  <h1 className="font-bold">Previous Tournaments</h1>
                  {previousTournaments.map((tournament, index) => (
                    <div
                      key={index}
                      className={`flex justify-between items-center h-16 rounded-lg my-5 cursor-pointer ${
                        selectedTournament?._id === tournament?._id
                          ? "bg-indigo-200"
                          : "bg-slate-100"
                      }`}
                      onClick={() => setSelectedTournament(tournament)}
                    >
                      <h1 className="ml-4">{tournament?.name}</h1>
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedTournament(tournament);
                        }}
                        className={`mr-4 inline-flex items-center gap-x-2 rounded-md px-1.5 py-1 text-sm font-semibold text-slate-400 shadow-sm ${
                          selectedTournament?._id === tournament?._id
                            ? "bg-indigo-600 text-white"
                            : "bg-slate-200 text-slate-400"
                        } `}
                      >
                        Points: {tournament?.points}
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GroupDetails;
