import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginImage from "../../assets/images/Login Image.png";
import LoginMobile from "../../assets/images/Login Mobile.jpg";
import Logo from "../../assets/images/Logo.png";
import { error } from "../../utils/notifications";
import { signIn } from "../../api/users.api";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { UserCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { send } from "../../utils/push";

const initialData = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState(initialData);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savedUsers, setSavedUsers] = useState([]);
  const [newLogin, setNewLogin] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("savedUsers")) {
      setSavedUsers(JSON.parse(localStorage.getItem("savedUsers")));
    }
  }, []);

  useEffect(() => {
    if (localStorage?.getItem("token") && localStorage?.getItem("user")) {
      navigate("/main");
    }
  }, [navigate]);

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    signIn(userData)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.result));
        if (res.data.result.role === "admin") navigate("/admin/users");
        else navigate(location?.state?.prevUrl || "/main");
      })
      .catch((err) => {
        console.log(err);
        error(err?.response?.data?.message || "An error occurred");
        setLoading(false);
      });
  };

  const handleSavedUserLogin = (savedUser) => {
    localStorage.setItem("user", JSON.stringify(savedUser.user));
    localStorage.setItem("token", savedUser.token);
    send("Login", "You have successfully logged in");
    navigate(location?.state?.prevUrl || "/main");
  };

  const removeSavedUser = (savedUser) => {
    const newSavedUsers = savedUsers?.filter(
      (user) => savedUser?.token !== user?.token
    );
    localStorage.setItem("savedUsers", JSON.stringify(newSavedUsers));
    setSavedUsers(newSavedUsers);
  };
  return (
    <>
      <div
        style={{
          backgroundImage: `url('${LoginMobile}')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        className="flex min-h-screen flex-1 lg:bg-white"
      >
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-8 xl:px-10">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img className="h-24 w-auto" src={Logo} alt="Your Company" />
              <h2 className="mt-4 text-2xl font-bold leading-6 tracking-tight text-gray-900">
                Welcome Back!
              </h2>
            </div>

            <div className="mt-10">
              <div>
                {!newLogin && savedUsers.length > 0 ? (
                  <>
                    <div className="grid grid-cols-2 gap-3 mt-10">
                      {savedUsers?.map((savedUser, i) => (
                        <div
                          className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-2 relative"
                          key={i}
                        >
                          <XCircleIcon
                            className="w-5 h-5 text-red-500 cursor-pointer right-2 top-2 absolute"
                            onClick={() => removeSavedUser(savedUser)}
                          />
                          <div className="px-6 py-4 flex flex-col items-center">
                            <UserCircleIcon
                              className="h-16 w-16 text-gray-300"
                              aria-hidden="true"
                            />
                            <p
                              className="w-full text-center font-bold text-sm text-ellipsis truncate overflow-hidden"
                              style={{ maxWidth: "200px" }}
                            >
                              {savedUser?.user?.name}
                            </p>
                          </div>

                          <div className="pt-2">
                            <button
                              className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded w-full text-sm"
                              onClick={() => handleSavedUserLogin(savedUser)}
                            >
                              Login
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                    <button
                      className="mt-6 flex w-full justify-center rounded-md border border-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-600 shadow-sm hover:bg-indigo-600 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        setNewLogin(true);
                      }}
                    >
                      Login to another account
                    </button>
                  </>
                ) : (
                  <form
                    method="POST"
                    className="space-y-6"
                    onSubmit={handleLogin}
                  >
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          placeholder="Enter your email"
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Password
                      </label>
                      <div className="mt-2">
                        <div className="flex justify-between items-center">
                          <input
                            id="password"
                            name="password"
                            type={visible ? "text" : "password"}
                            autoComplete="current-password"
                            required
                            placeholder="Enter your password"
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            onChange={handleChange}
                          />
                          <div className="ms-2 text-gray-500 bg-white py-2.5 px-2 rounded-md border cursor-pointer">
                            {visible ? (
                              <IoEyeOffOutline
                                onClick={() => {
                                  setVisible(!visible);
                                }}
                              />
                            ) : (
                              <IoEyeOutline
                                onClick={() => {
                                  setVisible(!visible);
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <Link
                        to={"/forgot-password"}
                        href="#"
                        className="text-sm font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        Forgot password?
                      </Link>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        disabled={loading}
                      >
                        {loading ? "Signing in..." : "Sign in"}
                      </button>
                    </div>
                  </form>
                )}
                {/* create a new account */}
                <div className="mt-6">
                  <p className="text-sm leading-6 text-gray-500">
                    New user?{" "}
                    <Link
                      to={"/signup"}
                      href="#"
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      Create an account
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={LoginImage}
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Login;
