import React from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { useState, useEffect } from "react";
import { getUsers } from "../../api/users.api";

const Dashboard = () => {
  return (
    <Layout >
      <h1>Dashboard</h1>
    </Layout>
  );
};

export default Dashboard;
