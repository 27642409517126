import { API } from "./api";

// export const signIn = (formData) => {
//   return API.post("/users/signin", formData);
// };

// export const signUp = (formData) => {
//   return API.post("/users/signup", formData);
// };

export const createTournament = (formData) => {
  return API.post("/tournaments", formData);
};

export const updateTournament = (id, formData) => {
  return API.put(`/tournaments/${id}`, formData);
};

export const getTournaments = () => {
  return API.get("/tournaments");
};

export const getTournamentSchedule = (id) => {
  return API.get(`/tournaments/${id}/schedule`);
};

export const getCurrentTournaments = () => {
  return API.get("/tournaments/current");
};

export const getUpcomingTournaments = () => {
  return API.get("/tournaments/upcoming");
};

export const getPreviousTournaments = () => {
  return API.get("/tournaments/previous");
};

export const getCurrentTournamentsForGroup = (id) => {
  return API.get(`/tournaments/group/${id}/current`);
};

export const getUpcomingTournamentsForGroup = (id) => {
  return API.get(`/tournaments/group/${id}/upcoming`);
};

export const getPreviousTournamentsForGroup = (id) => {
  return API.get(`/tournaments/group/${id}/previous`);
};

export const getTournamentTeams = (id) => {
  return API.get(`/tournaments/${id}/teams`);
};

export const getCurrentTournamentsLatestMatch = () => {
  return API.get("/tournaments/current/match/latest");
};
