import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Main from "../pages/users/Main";
import Global from "../pages/users/Global";
import Groups from "../pages/users/Groups";
import GroupDetails from "../pages/users/GroupDetails";
import Profile from "../pages/users/Profile";
import Tournaments from "../pages/admin/Tournaments";
import Teams from "../pages/admin/Teams";
import Matches from "../pages/admin/Matches";
import Dashboard from "../pages/admin/Dashboard";
import Users from "../pages/admin/Users";

const Navigation = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (!localStorage?.getItem("token") || !localStorage?.getItem("user")) {
      navigate("/", {
        state: {
          prevUrl: location.pathname,
        },
      });
    } else {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn, navigate]);

  if (isLoggedIn)
    return (
      <Routes>
        <Route path="/main" element={<Main />} />
        <Route path="/global" element={<Global />} />
        <Route path="/groups" element={<Groups />} />
        <Route path="/groups/:id" element={<GroupDetails />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/admin/dashboard" element={<Dashboard />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/tournaments" element={<Tournaments />} />
        <Route path="/admin/teams" element={<Teams />} />
        <Route path="/admin/matches" element={<Matches />} />
      </Routes>
    );
};

export default Navigation;
