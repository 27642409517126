import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

function SaveLoginInfoPopup({ open, setOpen }) {
  const navigate = useNavigate();

  const handleSaveUserInfo = () => {
    // Save the user's info in local storage
    const savedUsers = JSON.parse(localStorage.getItem("savedUsers")) || [];
    const loggedInUser = JSON.parse(localStorage.getItem("user"));
    const userToken = localStorage.getItem("token");
    const userInfo = {
      user: loggedInUser,
      token: userToken,
    };
    savedUsers.push(userInfo);
    localStorage.setItem("savedUsers", JSON.stringify(savedUsers));
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/");
    setOpen(false);
  };

  const handleNotNow = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/");
    setOpen(false);
  };

  return (
    <Dialog as="div" className="relative z-10" open={open} onClose={setOpen}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Modal content */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto max-w-sm rounded bg-white p-6 shadow-lg">
          <Dialog.Title className="flex items-center text-lg font-medium leading-6 text-gray-900">
            <ExclamationCircleIcon
              className="h-6 w-6 text-blue-500 mr-2"
              aria-hidden="true"
            />
            Save Login Info?
          </Dialog.Title>
          <Dialog.Description className="mt-2 text-sm text-gray-500">
            Do you want to save your login information for later use?
          </Dialog.Description>
          <div className="mt-4 flex justify-end space-x-3">
            <button
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded"
              onClick={() => {
                handleNotNow();
              }}
            >
              Not Now
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                // Handle save logic here
                handleSaveUserInfo();
              }}
            >
              Yes
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}

export default SaveLoginInfoPopup;
