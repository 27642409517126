import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import selectGroup from "../../../assets/images/Selesct Group.png";
import {
  checkIfTimeIsPassed,
  getLocalDateTime,
  getTimeFromDate,
} from "../../../utils/time";
import { StarIcon } from "@heroicons/react/24/solid";
import WinIcon from "../../../assets/images/Tournaments Coming Soon.png";
import { baseURL } from "../../../api/api";

const TodayMatches = ({
  participatedTournamentTodayMatches,
  setRulesPopupOpen,
  setMatchDetails,
  tournament,
  setMatchDetailsPopupOpen,
}) => {
  return (
    <div className="bg-white rounded-lg px-4 py-4 mb-24 sm:mb-4">
      <h1 className="">Today Matches</h1>
      {participatedTournamentTodayMatches.length > 0 ? (
        participatedTournamentTodayMatches.map((todayMatch, index) => {
          return todayMatch?.prediction === null ? (
            <div
              className="px-4 bg-slate-100 my-5 rounded-lg border"
              key={index}
            >
              <div className="flex justify-between items-center h-16">
                <h1 className="ml-2">{todayMatch?.tournament?.name}</h1>

                <button
                  type="button"
                  onClick={() => setRulesPopupOpen(true)}
                  className="mr-2 inline-flex items-center gap-x-2 rounded-md bg-slate-200 px-1.5 py-1  text-sm font-semibold text-slate-400 shadow-sm"
                >
                  RULES
                  <IoIosInformationCircleOutline
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>

              <div className="grid grid-cols-3 flex justify-center items-center rounded-md gap-2 bg-white py-4">
                <div className="col-span-1 flex flex-col justify-center items-center relative">
                  {todayMatch?.team1?._id === todayMatch?.winningTeam && (
                    <img
                      src={WinIcon}
                      alt="Descriptive Alt Text"
                      className="w-4 lg:w-6 h-auto absolute top-10 right-6"
                    />
                  )}
                  <img
                    src={`${baseURL}/public/${todayMatch?.team1?.image}`}
                    alt="Descriptive Alt Text"
                    className="w-16 lg:w-24 h-auto"
                  />
                  <h1 className="pt-2 text-center text-sm lg:text-lg font-semibold">
                    {todayMatch?.team1?.name}
                  </h1>
                </div>
                <div className="col-span-1">
                  <h1 className="text-center text-xs md:text-base lg:text-3xl font-bold">
                    VS
                  </h1>
                  {/* Get date */}
                  <div className="flex flex-col items-center">
                    <div className="text-xs md:text-base">
                      {getTimeFromDate(todayMatch?.dateTime)?.substring(
                        0,
                        getTimeFromDate(todayMatch?.dateTime).indexOf(" ")
                      )}
                    </div>
                    <div className="text-xs md:text-base">
                      {getTimeFromDate(todayMatch?.dateTime)?.substring(
                        getTimeFromDate(todayMatch?.dateTime).indexOf(" ") + 1
                      )}
                    </div>
                  </div>
                  {/* <h1 className="text-center text-sm font-medium text-slate-500 pt-3">
                                {
                                  tournament?.latestMatch?.dateTime?.split(
                                    "T"
                                  )[0]
                                }
                              </h1> */}
                </div>
                <div className="col-span-1 flex flex-col justify-center items-center relative">
                  {todayMatch?.team2?._id === todayMatch?.winningTeam && (
                    <img
                      src={WinIcon}
                      alt="Descriptive Alt Text"
                      className="w-4 lg:w-6 h-auto absolute top-10 right-6"
                    />
                  )}
                  <img
                    src={`${baseURL}/public/${todayMatch?.team2?.image}`}
                    alt="Descriptive Alt Text"
                    className="w-16 lg:w-24 h-auto"
                  />
                  <h1 className="pt-2 text-center text-sm lg:text-lg font-semibold">
                    {todayMatch?.team2?.name}
                  </h1>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center h-auto pb-4 sm:pb-0">
                {/* If user has favorite team, show it here */}
                {todayMatch?.participant?.favoriteTeam && (
                  <div className="bg-indigo-200 rounded-md p-2 opacity-80 flex space-x-2 my-3">
                    <StarIcon className="h-5 w-5 text-indigo-600" />
                    <h1 className="text-indigo-600 text-sm font-semibold">
                      Favorite Team:{" "}
                      {todayMatch?.participant?.favoriteTeam?.name}
                    </h1>
                  </div>
                )}
                {checkIfTimeIsPassed(todayMatch?.dateTime).isTimePassed ? (
                  // Red text for passed time
                  <h1 className="text-red-500 text-sm font-semibold my-3">
                    Sorry, Prediction Time Ended
                  </h1>
                ) : (
                  <button
                    type="button"
                    onClick={() => {
                      setMatchDetails(todayMatch);
                      setMatchDetailsPopupOpen(true);
                    }}
                    className="my-3 justify-center inline-flex items-center gap-x-2 rounded-md px-10 py-1  text-lg font-bold bg-indigo-600 hover:bg-indigo-700 text-white hover:text-white shadow-sm border border-indigo-500"
                  >
                    Predict Now
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div
              className="px-4 bg-slate-100 my-5 rounded-lg border"
              key={index}
            >
              <div className="flex justify-between items-center h-16">
                <h1 className="ml-2">{todayMatch?.tournament?.name}</h1>

                <button
                  type="button"
                  onClick={() => setRulesPopupOpen(true)}
                  className="mr-2 inline-flex items-center gap-x-2 rounded-md bg-slate-200 px-1.5 py-1  text-sm font-semibold text-slate-400 shadow-sm"
                >
                  RULES
                  <IoIosInformationCircleOutline
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>

              <div className="grid grid-cols-3 flex justify-center items-center rounded-md gap-2 bg-white py-4">
                <div
                  className={
                    todayMatch?.prediction?.predictedTeam?._id ===
                    todayMatch?.team1?._id
                      ? "col-span-1 flex flex-col justify-center items-center bg-indigo-200 rounded-md mx-2 sm:mx-10 sm:py-4 relative"
                      : "col-span-1 flex flex-col justify-center items-center relative"
                  }
                >
                  {todayMatch?.team1?._id === todayMatch?.winningTeam && (
                    <img
                      src={WinIcon}
                      alt="Descriptive Alt Text"
                      className="w-4 lg:w-6 h-auto absolute top-10 right-6"
                    />
                  )}
                  <img
                    src={`${baseURL}/public/${todayMatch?.team1?.image}`}
                    alt="Descriptive Alt Text"
                    className="w-16 lg:w-24 h-auto"
                  />
                  <h1 className="pt-2 text-center text-sm lg:text-lg font-semibold">
                    {todayMatch?.team1?.name}
                  </h1>
                </div>
                <div className="col-span-1">
                  <h1 className="text-center text-xs md:text-base lg:text-3xl font-bold">
                    VS
                  </h1>
                  {/* Get date */}
                  <div className="flex flex-col items-center">
                    <div className="text-xs md:text-base">
                    {getTimeFromDate(todayMatch?.dateTime)?.substring(
                        0,
                        getTimeFromDate(todayMatch?.dateTime).indexOf(" ")
                      )}
                    </div>
                    <div className="text-xs md:text-base">
                    {getTimeFromDate(todayMatch?.dateTime)?.substring(
                        getTimeFromDate(todayMatch?.dateTime).indexOf(" ") + 1
                      )}
                    </div>
                  </div>
                  {/* <h1 className="text-center text-sm font-medium text-slate-500 pt-3">
                                {
                                  tournament?.latestMatch?.dateTime?.split(
                                    "T"
                                  )[0]
                                }
                              </h1> */}
                </div>
                <div
                  className={
                    todayMatch?.prediction?.predictedTeam?._id ===
                    todayMatch?.team2?._id
                      ? "col-span-1 flex flex-col justify-center items-center bg-indigo-200 rounded-md mx-2 sm:mx-10 sm:py-4 relative"
                      : "col-span-1 flex flex-col justify-center items-center relative"
                  }
                >
                  {todayMatch?.team2?._id === todayMatch?.winningTeam && (
                    <img
                      src={WinIcon}
                      alt="Descriptive Alt Text"
                      className="w-4 lg:w-6 h-auto absolute top-10 right-6"
                    />
                  )}
                  <img
                    src={`${baseURL}/public/${todayMatch?.team2?.image}`}
                    alt="Descriptive Alt Text"
                    className="w-16 lg:w-24 h-auto"
                  />
                  <h1 className="pt-2 text-center text-sm lg:text-lg font-semibold">
                    {todayMatch?.team2?.name}
                  </h1>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center h-auto pb-4 sm:pb-0">
                {/* If user has favorite team, show it here */}
                {todayMatch?.participant?.favoriteTeam && (
                  <div className="bg-indigo-200 rounded-md p-2 opacity-80 flex space-x-2 my-3">
                    <StarIcon className="h-5 w-5 text-indigo-600" />
                    <h1 className="text-indigo-600 text-sm font-semibold">
                      Favorite Team:{" "}
                      {todayMatch?.participant?.favoriteTeam?.name}
                    </h1>
                  </div>
                )}
                <h1 className="text-green-700 text-sm font-semibold">
                  Your Prediction: {todayMatch?.prediction?.predictedTeam?.name}
                </h1>
              </div>
            </div>
          );
        })
      ) : (
        <div className="bg-transparent rounded-lg px-4 py-4 mb-4">
          <div className="flex flex-col items-center">
            <img
              className="w-64 h-auto"
              src={selectGroup}
              alt="Select Tournament"
            />
            <h1 className="text-lg text-gray-400 font-mediun mb-12">
              No Matches to Show
            </h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default TodayMatches;
