import React from "react";
import LahoreQalandars from "../../../assets/images/LQ.png";
import MultanSultans from "../../../assets/images/MS.png";
import { IoIosInformationCircleOutline } from "react-icons/io";
import selectGroup from "../../../assets/images/Selesct Group.png";
import { getLocalDateTime, getTimeFromDate } from "../../../utils/time";
import WinIcon from "../../../assets/images/Tournaments Coming Soon.png";
import { baseURL } from "../../../api/api";

const PreviousMatches = ({
  participatedTournamentPreviousMatches,
  setRulesPopupOpen,
  setMatchDetails,
  setMatchDetailsPopupOpen,
}) => {
  return (
    <div className="bg-white rounded-lg px-4 py-4 mb-24 sm:mb-4">
      <h1 className="">Previous Matches</h1>
      {participatedTournamentPreviousMatches.length > 0 ? (
        participatedTournamentPreviousMatches.map((match, index) => {
          return match?.prediction === null ? (
            <div
              className="px-4 bg-slate-100 my-5 rounded-lg border"
              key={index}
            >
              <div className="flex justify-between items-center h-16">
                <h1 className="ml-2">{match?.tournament?.name}</h1>

                <button
                  type="button"
                  onClick={() => setRulesPopupOpen(true)}
                  className="mr-2 inline-flex items-center gap-x-2 rounded-md bg-slate-200 px-1.5 py-1  text-sm font-semibold text-slate-400 shadow-sm"
                >
                  RULES
                  <IoIosInformationCircleOutline
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>

              <div className="grid grid-cols-3 flex justify-center items-center rounded-md gap-2 bg-white py-4 relative">
                <div className="col-span-1 flex flex-col justify-center items-center relative">
                  {match?.team1?._id === match?.winningTeam?._id && (
                    <img
                      src={WinIcon}
                      alt="Descriptive Alt Text"
                      className="w-4 lg:w-6 h-auto absolute top-10 right-6"
                    />
                  )}
                  <img
                    src={`${baseURL}/public/${match?.team1?.image}`}
                    alt="Descriptive Alt Text"
                    className="w-16 lg:w-24 h-auto"
                  />
                  <h1 className="pt-2 text-center text-sm lg:text-lg font-semibold">
                    {match?.team1?.name}
                  </h1>
                </div>
                <div className="col-span-1">
                  <h1 className="text-center text-xs md:text-base lg:text-3xl font-bold">
                    VS
                  </h1>
                  {/* Get date */}
                  <div className="flex flex-col items-center">
                    <div className="text-xs md:text-base">
                      {getTimeFromDate(match?.dateTime)?.substring(
                        0,
                        getTimeFromDate(match?.dateTime).indexOf(" ")
                      )}
                    </div>
                    <div className="text-xs md:text-base">
                      {getTimeFromDate(match?.dateTime)?.substring(
                        getTimeFromDate(match?.dateTime).indexOf(" ") + 1
                      )}
                    </div>
                  </div>
                  {/* <h1 className="text-center text-sm font-medium text-slate-500 pt-3">
                                {
                                  tournament?.latestMatch?.dateTime?.split(
                                    "T"
                                  )[0]
                                }
                              </h1> */}
                </div>
                <div className="col-span-1 flex flex-col justify-center items-center relative">
                  {match?.team2?._id === match?.winningTeam?._id && (
                    <img
                      src={WinIcon}
                      alt="Descriptive Alt Text"
                      className="w-4 lg:w-6 h-auto absolute top-10 right-6"
                    />
                  )}
                  <img
                    src={`${baseURL}/public/${match?.team2?.image}`}
                    alt="Descriptive Alt Text"
                    className="w-16 lg:w-24 h-auto"
                  />
                  <h1 className="pt-2 text-center text-sm lg:text-lg font-semibold">
                    {match?.team2?.name}
                  </h1>
                </div>
              </div>
              <div className="flex items-center h-16">
                {match?.winningTeam?.name && (
                  <div className="flex items-center space-x-4">
                    <div>
                      <img src={WinIcon} className="w-3 ms-4" />
                    </div>
                    <div className="text-green-600 font-medium">
                      Winner:
                      {match?.winningTeam?.name}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className="px-4 bg-slate-100 my-5 rounded-lg border"
              key={index}
            >
              <div className="flex justify-between items-center h-16">
                <h1 className="ml-2">{match?.tournament?.name}</h1>

                <button
                  type="button"
                  onClick={() => setRulesPopupOpen(true)}
                  className="mr-2 inline-flex items-center gap-x-2 rounded-md bg-slate-200 px-1.5 py-1  text-sm font-semibold text-slate-400 shadow-sm"
                >
                  RULES
                  <IoIosInformationCircleOutline
                    className="h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>

              <div className="grid grid-cols-3 flex justify-center items-center rounded-md gap-2 bg-white py-4">
                <div
                  className={
                    match?.prediction?.predictedTeam?._id === match?.team1?._id
                      ? "col-span-1 flex flex-col justify-center items-center bg-indigo-200 rounded-md mx-2 sm:mx-10 sm:py-4 relative"
                      : "col-span-1 flex flex-col justify-center items-center relative"
                  }
                >
                  {match?.team1?._id === match?.winningTeam?._id && (
                    <img
                      src={WinIcon}
                      alt="Descriptive Alt Text"
                      className="w-4 lg:w-6 h-auto absolute top-10 right-6"
                    />
                  )}
                  <img
                    src={`${baseURL}/public/${match?.team1?.image}`}
                    alt="Descriptive Alt Text"
                    className="w-16 lg:w-24 h-auto"
                  />
                  <h1 className="pt-2 text-center text-sm lg:text-lg font-semibold">
                    {match?.team1?.name}
                  </h1>
                </div>
                <div className="col-span-1">
                  <h1 className="text-center text-xs md:text-base lg:text-3xl font-bold">
                    VS
                  </h1>
                  <div className="flex flex-col items-center">
                    <div className="text-xs md:text-base">
                      {getTimeFromDate(match?.dateTime)?.substring(
                        0,
                        getTimeFromDate(match?.dateTime).indexOf(" ")
                      )}
                    </div>
                    <div className="text-xs md:text-base">
                      {getTimeFromDate(match?.dateTime)?.substring(
                        getTimeFromDate(match?.dateTime).indexOf(" ") + 1
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    match?.prediction?.predictedTeam?._id === match?.team2?._id
                      ? "col-span-1 flex flex-col justify-center items-center bg-indigo-200 rounded-md mx-2 sm:mx-10 sm:py-4 relative"
                      : "col-span-1 flex flex-col justify-center items-center relative"
                  }
                >
                  {match?.team2?._id === match?.winningTeam?._id && (
                    <img
                      src={WinIcon}
                      alt="Descriptive Alt Text"
                      className="w-4 lg:w-6 h-auto absolute top-10 right-6"
                    />
                  )}
                  <img
                    src={`${baseURL}/public/${match?.team2?.image}`}
                    alt="Descriptive Alt Text"
                    className="w-16 lg:w-24 h-auto"
                  />
                  <h1 className="pt-2 text-center text-sm lg:text-lg font-semibold">
                    {match?.team2?.name}
                  </h1>
                </div>
              </div>
              <div className="flex items-center h-16">
                {match?.winningTeam?.name && (
                  <div className="flex items-center space-x-4">
                    <div>
                      <img src={WinIcon} className="w-3 ms-4" />
                    </div>
                    <div className="text-green-600 font-medium">
                      Winner:
                      {match?.winningTeam?.name}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })
      ) : (
        <div className="bg-transparent rounded-lg px-4 py-4 mb-4">
          <div className="flex flex-col items-center">
            <img className="w-64 h-auto" src={selectGroup} />
            <h1 className="text-lg text-gray-400 font-mediun mb-12">
              No Matches to Show
            </h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviousMatches;
