import React from "react";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

const RulesPopup = ({ open, setOpen, tournament }) => {
  const cancelButtonRef = useRef();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-xl font-semibold leading-6 text-gray-900"
                    >
                      {tournament?.name} Rules
                    </Dialog.Title>
                  </div>
                  <div className="max-w-xl mx-auto p-6 bg-white shadow-sm rounded-xl">
                    <div className="space-y-4">
                      <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
                        <h3 className="text-lg font-semibold">Regular Match</h3>
                        <div>
                          <p className="text-indigo-600 font-bold">
                            Win: {tournament?.rules?.regularPoints}
                          </p>
                          <p className="text-gray-400 font-bold">Loss: 0</p>
                        </div>
                      </div>

                      <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
                        <h3 className="text-lg font-semibold">
                          Knockout Match
                        </h3>
                        <div>
                          <p className="text-indigo-600 font-bold">
                            Win: {tournament?.rules?.knockoutPoints}
                          </p>
                          <p className="text-gray-400 font-bold">Loss: 0</p>
                        </div>
                      </div>

                      <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg">
                        <h3 className="text-lg font-semibold">Final Match</h3>
                        <div>
                          <p className="text-indigo-600 font-bold">
                            Win: {tournament?.rules?.finalPoints}
                          </p>
                          <p className="text-gray-400 font-bold">Loss: 0</p>
                        </div>
                      </div>
                    </div>
                    {tournament?.rules?.favoriteTeamPoints && (
                      <>
                        <div className="mt-5">
                          <h3 className="text-lg font-semibold">
                            Favorite Team
                          </h3>
                          <p className="text-gray-500">
                            If favorite team wins a match, you will get
                            following points.
                          </p>
                        </div>
                        <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg mt-2">
                          <h3 className="text-lg font-semibold">
                            Regular Match
                          </h3>
                          <div>
                            <p className="text-indigo-600 font-bold">
                              Win:{" "}
                              {tournament?.rules?.favoriteTeamRegularPoints}
                            </p>
                            <p className="text-gray-400 font-bold">Loss: 0</p>
                          </div>
                        </div>
                        <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg mt-2">
                          <h3 className="text-lg font-semibold">
                            Knockout Match
                          </h3>
                          <div>
                            <p className="text-indigo-600 font-bold">
                              Win:{" "}
                              {tournament?.rules?.favoriteTeamKnockoutPoints}
                            </p>
                            <p className="text-gray-400 font-bold">Loss: 0</p>
                          </div>
                        </div>
                        <div className="flex justify-between items-center bg-gray-100 p-4 rounded-lg mt-2">
                          <h3 className="text-lg font-semibold">Final Match</h3>
                          <div>
                            <p className="text-indigo-600 font-bold">
                              Win: {tournament?.rules?.favoriteTeamFinalPoints}
                            </p>
                            <p className="text-gray-400 font-bold">Loss: 0</p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={() => setOpen(false)}
                    >
                      Done
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RulesPopup;
