import React, { useEffect, useState } from "react";
import Header from "./components/Header";
import { HiUserCircle } from "react-icons/hi2";
import { getProfileInformation } from "../../api/users.api";
import HeaderImage from "../../assets/images/Header Image.png";

function Profile() {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setLoggedInUser(user);
  }, []);

  useEffect(() => {
    if (loggedInUser) {
      getProfileInformation()
        .then((res) => {
          setUser(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [loggedInUser]);

  return (
    <>
      <div className="bg-gray-100 min-h-screen">
        <Header />
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-12 md:py-16 lg:px-8 mx-6 lg:mx-12 rounded-lg mt-6">
          <img
            src={HeaderImage}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div
            className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div
            className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
            aria-hidden="true"
          >
            <div
              className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-2xl font-bold tracking-tight text-white md:text-4xl">
              Your Profile
            </h2>
            <p className="mt-4 text-sm leading-normal text-gray-300">
              Know your stats and keep track of your progress.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-3 mx-6 lg:mx-12 min-h-32 pt-6 lg:pb-4 pb-20">
          <div className="col-span-3 lg:col-span-1">
            <div className="bg-white p-4 rounded-lg">
              <div className="flex flex-col items-center">
                <HiUserCircle className="text-9xl text-gray-300" />
                <h2 className="mt-1 font-medium text-lg">
                  {user?.personalInfo?.name}
                </h2>
                <h2 className="font-regular text-md text-gray-400">
                  {user?.personalInfo?.email}
                </h2>
                <div className="mt-2">{/* Progress tracker here */}</div>
              </div>
            </div>
          </div>
          <div className="col-span-3 lg:col-span-2">
            <div className="bg-white p-4 rounded-lg">
              <h3 className="font-medium text-lg mb-4">Metrics</h3>
              <div className="grid grid-cols-3 gap-4">
                <div className="col-span-3 lg:col-span-1 bg-white p-4 rounded-lg shadow lg:block flex justify-between items-center">
                  <h4 className="font-medium text-gray-500">Joined Groups</h4>
                  <p className="text-4xl mt-2 text-indigo-600 font-bold">
                    {user?.groupsJoined}
                  </p>
                </div>
                <div className="col-span-3 lg:col-span-1 bg-white p-4 rounded-lg shadow lg:block flex justify-between items-center">
                  <h4 className="font-medium text-gray-500">
                    Participated Tournaments
                  </h4>
                  <p className="text-4xl mt-2 text-indigo-600 font-bold">
                    {user?.tournamentsJoined}
                  </p>
                </div>
                <div className="col-span-3 lg:col-span-1 bg-white p-4 rounded-lg shadow">
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center pb-4">
                      <h4 className="font-medium text-gray-500">
                        Total Predictions
                      </h4>
                      <p className="text-4xl mt-2 text-indigo-600 font-bold">
                        {user?.predictionsMade}
                      </p>
                    </div>
                    <div className="flex justify-between items-center">
                      <p className="font-medium text-gray-500">
                        Correct Predictions
                      </p>
                      <p className="text-4xl mt-2 text-indigo-600 font-bold">
                        {user?.correctPredictions}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
