import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState, useEffect } from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";
import LahoreQalandars from "../../../assets/images/LQ.png";
import MultanSultans from "../../../assets/images/MS.png";
import { createPrediction } from "../../../api/predictions.api";
import { error, success, warning } from "../../../utils/notifications";
import { checkIfTimeIsPassed } from "../../../utils/time";
import { baseURL } from "../../../api/api";

const GetPredictionPopup = ({
  open,
  setOpen,
  group,
  matchDetails,
  getMatchesForPrediction,
}) => {
  const cancelButtonRef = useRef();
  const [prediction, setPrediction] = useState({});
  const [predictionLoading, setPredictionLoading] = useState(false);

  const handlePredictionSubmit = async (e) => {
    e.preventDefault();
    if (!prediction.predictedTeam) {
      warning("Please select a team to predict");
      return;
    } else {
      setPredictionLoading(true);
      await createPrediction({
        ...prediction,
        group: group,
        match: matchDetails?._id,
        participant: matchDetails?.participant?._id,
      })
        .then((res) => {
          success("Prediction Added Successfully");
          getMatchesForPrediction();
          setOpen(false);
          setPrediction({});
          setPredictionLoading(false);
        })
        .catch((err) => {
          setPredictionLoading(false);
          error("An error occurred while adding prediction");
          console.log(err);
        });
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {checkIfTimeIsPassed(matchDetails?.dateTime)?.isTimePassed ? (
                  <div className="text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Prediction Time Passed
                    </Dialog.Title>
                  </div>
                ) : (
                  <form onSubmit={handlePredictionSubmit}>
                    <div>
                      <div className="text-center">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Predict Your Team
                        </Dialog.Title>
                      </div>
                      <div className="flex justify-between mt-4">
                        <h1 className="text-sm font-medium text-lg text-gray-400">
                          {matchDetails?.tournament?.name}
                        </h1>
                      </div>
                      <div className="grid grid-cols-3 gap-3 flex items-center justify-items-center p-4 bg-slate-100 rounded-lg mt-4">
                        <div
                          className={
                            prediction?.predictedTeam ===
                            matchDetails?.team1?._id
                              ? "flex flex-col items-center border-2 border-indigo-600 rounded-md px-2"
                              : "flex flex-col items-center"
                          }
                        >
                          <label
                            htmlFor={matchDetails?.team1?._id}
                            className="flex flex-col items-center"
                          >
                            <img
                              src={`${baseURL}/public/${matchDetails?.team1?.image}`}
                              className="w-24 h-auto"
                            />
                            <h1 className="font-medium my-2">
                              {matchDetails?.team1?.name}
                            </h1>
                          </label>
                          <input
                            id={matchDetails?.team1?._id}
                            value={matchDetails?.team1?._id}
                            type="radio"
                            className="hidden"
                            onChange={() => {
                              setPrediction({
                                ...prediction,
                                predictedTeam: matchDetails?.team1?._id,
                              });
                            }}
                            checked={
                              prediction.predictedTeam ===
                              matchDetails?.team1?._id
                            }
                          />
                        </div>
                        <div>
                          <div className="bg-slate-200 rounded-md p-5">
                            <h1 className="text-xl text-slate-400 font-semibold">
                              VS
                            </h1>
                          </div>
                        </div>
                        <div
                          className={
                            prediction?.predictedTeam ===
                            matchDetails?.team2?._id
                              ? "flex flex-col items-center border-2 border-indigo-600 rounded-md px-2"
                              : "flex flex-col items-center"
                          }
                        >
                          <label
                            htmlFor={matchDetails?.team2?._id}
                            className="flex flex-col items-center"
                          >
                            <img
                              src={`${baseURL}/public/${matchDetails?.team2?.image}`}
                              className="w-24 h-auto ms-2"
                            />
                            <h1 className="font-medium my-2">
                              {matchDetails?.team2?.name}
                            </h1>
                          </label>
                          <input
                            id={matchDetails?.team2?._id}
                            value={matchDetails?.team2?._id}
                            type="radio"
                            className="hidden"
                            onChange={() => {
                              setPrediction({
                                ...prediction,
                                predictedTeam: matchDetails?.team2?._id,
                              });
                            }}
                            checked={
                              prediction.predictedTeam ===
                              matchDetails?.team2?._id
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="submit"
                        disabled={predictionLoading}
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      >
                        {predictionLoading ? "Loading..." : "Predict Now"}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => {
                          setOpen(false);
                        }}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GetPredictionPopup;
