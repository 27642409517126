import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { getUsers } from "../../../api/users.api";
import { createGroup } from "../../../api/groups.api";

const DefineRulesPopup = ({
  open,
  setOpen,
  tournament,
  handleJoinTournament,
}) => {
  const cancelButtonRef = useRef();
  const [favoriteTeam, setFavoriteTeam] = useState(null);
  const [rules, setRules] = useState({
    regularPoints: 2,
    knockoutPoints: 3,
    finalPoints: 4,
    favoriteTeamPoints: false,
    favoriteTeamRegularPoints: 3,
    favoriteTeamKnockoutPoints: 4,
    favoriteTeamFinalPoints: 5,
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {tournament?.name} Rules
                    </Dialog.Title>
                  </div>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleJoinTournament(
                        tournament?._id,
                        rules,
                        favoriteTeam
                      );
                    }}
                  >
                    {/* ... existing Group Name input ... */}

                    {/* Rules inputs */}
                    <div className="mt-4 grid grid-cols-2 items-center gap-4">
                      <label
                        htmlFor="regularPoints"
                        className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                      >
                        Regular Match Points
                      </label>
                      <input
                        type="number"
                        id="regularPoints"
                        name="regularPoints"
                        onChange={(e) =>
                          setRules({ ...rules, regularPoints: e.target.value })
                        }
                        defaultValue={2}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                        style={{ flex: 1 }}
                      />
                    </div>
                    <div className="mt-4 grid grid-cols-2 items-center gap-4">
                      <label
                        htmlFor="knockoutPoints"
                        className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                      >
                        Knockout Match Points
                      </label>
                      <input
                        type="number"
                        id="knockoutPoints"
                        name="knockoutPoints"
                        onChange={(e) =>
                          setRules({ ...rules, knockoutPoints: e.target.value })
                        }
                        defaultValue={3}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                        style={{ flex: 1 }}
                      />
                    </div>
                    <div className="mt-4 grid grid-cols-2 items-center gap-4">
                      <label
                        htmlFor="finalPoints"
                        className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                      >
                        Final Match Points
                      </label>
                      <input
                        type="number"
                        id="finalPoints"
                        name="finalPoints"
                        onChange={(e) =>
                          setRules({ ...rules, finalPoints: e.target.value })
                        }
                        defaultValue={4}
                        required
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                        style={{ flex: 1 }}
                      />
                    </div>

                    {/* ... similar inputs for knockoutPoints and finalPoints ... */}

                    <div className="mt-4">
                      <label
                        htmlFor="favoriteTeamPoints"
                        className="flex items-center text-sm font-medium leading-6 text-gray-900"
                      >
                        <input
                          type="checkbox"
                          id="favoriteTeamPoints"
                          name="favoriteTeamPoints"
                          checked={rules?.favoriteTeamPoints}
                          onChange={() =>
                            setRules({
                              ...rules,
                              favoriteTeamPoints: !rules?.favoriteTeamPoints,
                            })
                          }
                          className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        />
                        <span className="ml-2 text-sm font-medium text-gray-900">
                          Favorite Team Points
                        </span>
                      </label>
                    </div>

                    {rules?.favoriteTeamPoints && (
                      <div className="space-y-4 mt-4">
                        <div className="mt-4 grid grid-cols-2 items-center gap-4">
                          <label
                            htmlFor="favoriteTeamRegularPoints"
                            className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                          >
                            Regular Match Points
                          </label>
                          <input
                            type="number"
                            id="favoriteTeamRegularPoints"
                            name="favoriteTeamRegularPoints"
                            onChange={(e) =>
                              setRules({
                                ...rules,
                                favoriteTeamRegularPoints: e.target.value,
                              })
                            }
                            defaultValue={3}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                            style={{ flex: 1 }}
                          />
                        </div>
                        <div className="mt-4 grid grid-cols-2 items-center gap-4">
                          <label
                            htmlFor="favoriteTeamKnockoutPoints"
                            className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                          >
                            Knockout Match Points
                          </label>
                          <input
                            type="number"
                            id="favoriteTeamKnockoutPoints"
                            name="favoriteTeamKnockoutPoints"
                            onChange={(e) =>
                              setRules({
                                ...rules,
                                favoriteTeamKnockoutPoints: e.target.value,
                              })
                            }
                            defaultValue={4}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                            style={{ flex: 1 }}
                          />
                        </div>
                        <div className="mt-4 grid grid-cols-2 items-center gap-4">
                          <label
                            htmlFor="favoriteTeamFinalPoints"
                            className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                          >
                            Final Match Points
                          </label>
                          <input
                            type="number"
                            id="favoriteTeamFinalPoints"
                            name="favoriteTeamFinalPoints"
                            onChange={(e) =>
                              setRules({
                                ...rules,
                                favoriteTeamFinalPoints: e.target.value,
                              })
                            }
                            defaultValue={5}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                            style={{ flex: 1 }}
                          />
                        </div>

                        <hr />

                        <div className="mt-4 grid grid-cols-2 items-center gap-4">
                          <label
                            htmlFor="favoriteTeam"
                            className="block text-sm font-medium leading-6 text-gray-900 mr-4"
                          >
                            Your Favorite Team
                          </label>
                          <select
                            id="favoriteTeam"
                            name="favoriteTeam"
                            onChange={(e) => setFavoriteTeam(e.target.value)}
                            defaultValue={5}
                            required
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                            style={{ flex: 1 }}
                          >
                            <option value="">Select Favorite Team</option>
                            {tournament?.teams?.map((team) => (
                              <option value={team._id} key={team?._id}>
                                {team.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* ... similar inputs for favoriteTeamKnockoutPoints and favoriteTeamFinalPoints ... */}
                      </div>
                    )}

                    {/* Submit and Cancel buttons */}
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="submit"
                        className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      >
                        Set Rules & Join
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DefineRulesPopup;
